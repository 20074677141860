import { ApolloError, ApolloQueryResult, DocumentNode, OperationVariables } from '@apollo/client';
import * as VueApolloComposable from '@vue/apollo-composable';

// interface UseQueryReturnLazy<TResult, TVariables extends OperationVariables> extends VueApolloComposable.UseQueryReturn<TResult, TVariables> {
//     load: (document?: DocumentNode, variables?: TVariables, options?: VueApolloComposable.UseQueryOptions) => void;
// }

export type LazyReturnType<QueryResult, Variables extends OperationVariables> = ReturnType<
    typeof VueApolloComposable.useLazyQuery<QueryResult, Variables>
>;

export const awaitableGQLLazyQuery = async <QueryResult, Variables extends OperationVariables>(query: LazyReturnType<QueryResult, Variables>) => {
    return new Promise<ApolloQueryResult<QueryResult>>((resolve, reject) => {
        query.onResult((data: ApolloQueryResult<QueryResult>) => {
            if (!data.loading) {
                resolve(data);
            }
        });

        query.onError((err: ApolloError) => {
            reject(err);
        });

        if (query.forceDisabled) {
            query.load();
        } else {
            query.refetch();
        }
    });
};

export const awaitableGQLQuery = async <QueryResult, Variables extends OperationVariables>(
    query: VueApolloComposable.UseQueryReturn<QueryResult, Variables>,
) => {
    return new Promise<ApolloQueryResult<QueryResult>>((resolve, reject) => {
        query.onResult((data: ApolloQueryResult<QueryResult>) => {
            if (!data.loading) {
                resolve(data);
            }
        });

        query.onError((err: ApolloError) => {
            reject(err);
        });

        // if already loading, leave it alone
        if (!query.loading) {
            query.refetch();
        }
    });
};
