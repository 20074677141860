import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core';
import { DefaultApolloClient, provideApolloClient } from '@vue/apollo-composable';
import { RuntimeConfig } from 'nuxt/schema';

export const paramsArrayToString = (input: string | string[]): string => {
    return Array.isArray(input) ? input[0] : input;
};

export const setApolloClient = () => {
    provideApolloClient(getApolloClient());
};

const getApolloClient = () => {
    const getCookie = function (name: string) {
        if (process.server || typeof document === 'undefined') {
            return null;
        }

        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) return match[2];
    };

    const getHeaders = () => {
        const headers: { Authorization?: string; 'Content-Type'?: string } = {};
        const token = getCookie('apollo_default_token');
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
        headers['Content-Type'] = 'application/json';
        return headers;
    };

    const config = useRuntimeConfig();

    // Create an http link:
    const httpLink = new HttpLink({
        uri: config.public.GRAPHQL_URL,
        fetch: (uri: RequestInfo, options: RequestInit) => {
            options.headers = getHeaders();
            return fetch(uri, options);
        },
    });

    const client = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
    });

    return client;
};
