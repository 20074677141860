/* eslint camelcase: ["error", {allow: ["^PageByUrlQuery_","^BikeFinderQuery_","^ShopContentQuery_","^WebsiteQuery_"]}] */

import { defineStore } from 'pinia';

import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useI18nStore } from './i18n';
import { WebsiteQuery, BikefinderQuery, useBikefinderQuery, useWebsiteQuery, useShopContentLazyQuery } from '~~/graphql/generated';

import {
    BikeFinderQuery_BikeFinder,
    BikeFinderQuery_Collection,
    BikeFinderQuery_Collection_Products_Items,
    PageByUrlQuery_PageByUrl,
    ShopContentQuery_ShopContent,
    ShopContentQuery_ShopContentItem,
    WebsiteQuery_Website,
    WebsiteQuery_Website_SEO_NonNullable,
} from '~~/common/types/nestedGraphqlTypes';
import { awaitableGQLLazyQuery, awaitableGQLQuery } from '~/graphql/graphql-helpers';
import { setApolloClient } from '~/common/helpers';
import { LocaleType } from '~/plugins/i18n';
import { getPageByUrlQueryData, getWebsiteQueryData } from '~/common/api-helpers';
const langCountryMatch = /^[A-Za-z]{2}[_-]([A-Za-z0-9]{2,3})?/;

const DEFAULT_COUNTRY = 'INT';
const days = 60;

export type LoadShopInfoReturnType = (ShopContentQuery_ShopContentItem | undefined)[];

export type NotificationPayload = {
    type: string;
    message: string;
    sticky: boolean;
};

type BikesByRangeType = {
    code: string;
    sort: number;
    bikes: string[];
};

type AppStateType = {
    country: string;
    lang: string;
    multidomain: any; // UNKNOWN_TYPE
    theme: string;
    menu: boolean;
    baseUrl: string;
    header: string;
    originalHref: string | null; // UNKNOWN_TYPE
    shoppingCart: boolean;
    website: WebsiteQuery_Website | null;
    shopInfo: ShopContentQuery_ShopContent | null;
    main_menu: null; // UNKNOWN_TYPE
    pageCache: PageByUrlQuery_PageByUrl | null;
    relatedProducts: boolean;
    preview: boolean;
    notificationQueue: NotificationPayload[];
    bikefinder: any;
    bikefinderLinks: any[] | undefined | null;
    bikes: BikeFinderQuery_Collection_Products_Items;
    bikesByRange: BikesByRangeType[];
    notifications: any[] | null; // UNKNOWN_TYPE
    orderCodes: any[]; // UNKNOWN_TYPE
    ci: string;
};

const defaultAppState: AppStateType = {
    country: DEFAULT_COUNTRY,
    lang: 'en',
    // if called in a multidomain setup, this value contains the subdomain component
    multidomain: null,
    theme: 'light',
    menu: false,
    baseUrl: '',
    header: '',
    // for testing
    originalHref: null,
    shoppingCart: false,
    website: null,
    shopInfo: null,
    main_menu: null,
    pageCache: null,
    relatedProducts: false,
    // TODO - this set's the preview by default
    preview: false,
    notificationQueue: [],
    bikefinder: null,
    bikefinderLinks: [],
    bikes: [],
    bikesByRange: [],
    // these are the notifications to be displayed
    notifications: null,
    orderCodes: [],
    ci: 'v2',
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
const state = ref<AppStateType>(defaultAppState);

export type UnitsType = {
    metric: boolean;
    imperial: boolean;
};

const mutations = {
    headers: (header: string) => {
        state.value.header = header;
    },
    setHref: (href: string) => {
        state.value.originalHref = href;
    },
    setMultidomain: (multidomain: any) => {
        // UNKNOWN_TYPE
        state.value.multidomain = multidomain;
    },
    showRelatedProducts: (flag: boolean) => {
        state.value.relatedProducts = flag;
    },
    setNotifications: (nots: any[]) => {
        // UNKNOWN_TYPE
        if (!Array.isArray(nots)) {
            state.value.notifications = [];
        } else {
            state.value.notifications = nots;
        }
    },
    setTheme: (theme: string) => {
        state.value.theme = theme;
    },
    setCI: (value: string) => {
        if (['v1', 'v2'].includes(value)) {
            state.value.ci = value;
        }
    },
    setPreview: (preview: boolean) => {
        state.value.preview = preview;
    },
    setLangCountry: (langCountry: { lang: string; country: string } | string) => {
        // console.log('setting state', langCountry);
        if (typeof langCountry === 'string') {
            if (langCountry.indexOf('_') > 0) {
                const s = langCountry.split('_');
                state.value.lang = s[0];
                state.value.country = s[1].toUpperCase();
            } else {
                state.value.lang = langCountry;
                state.value.country = DEFAULT_COUNTRY;
            }
        } else if (typeof langCountry === 'object') {
            state.value.country = langCountry.country;
            state.value.lang = langCountry.lang;
        }
        if (process.client) {
            // set the cookie at this point
            // console.log('set cookie', `${state.lang}_${state.country.toUpperCase()}`)
            let expires = '';
            if (days) {
                const date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = '; expires=' + date.toUTCString();
            }
            document.cookie = 'lang=' + `${state.value.lang}_${state.value.country.toUpperCase()}` + expires + '; path=/';
        }
    },
    toggleMenu(isVisible: boolean) {
        state.value.menu = isVisible;
    },
    toggleShoppingCart: (isVisible: boolean) => {
        state.value.shoppingCart = isVisible;
    },
    enqueueNotification: (notification: NotificationPayload) => {
        state.value.notificationQueue.push(notification);
    },
    dequeueNotification: () => {
        if (state.value.notificationQueue.length > 0) {
            state.value.notificationQueue.shift();
        }
    },
    setBaseUrl: (url: string) => {
        state.value.baseUrl = url;
    },
    saveWebsite: (website: WebsiteQuery_Website) => {
        // store the website
        state.value.website = website;
    },
    // clearMenu(state) {
    //   state.main_menu = null;
    // },
    clear: () => {
        state.value.main_menu = null;
        state.value.notifications = null;
        state.value.shopInfo = null;
        state.value.bikefinder = null;
        state.value.bikefinderLinks = [];
        state.value.bikes = [];
        state.value.bikesByRange = [];
    },
    // saveMenu(state, menu) {
    //   state.main_menu = menu;
    // },
    savePage: (pageData: PageByUrlQuery_PageByUrl) => {
        state.value.pageCache = pageData;
    },
    saveShopInfo(info: ShopContentQuery_ShopContent) {
        state.value.shopInfo = info;
    },
    // bikefinder(state, bikes) {
    //   state.bikefinder = bikes;
    // },
    setBikefinder(bf: BikeFinderQuery_BikeFinder) {
        if (bf) {
            state.value.bikefinder = bf.bikefinder;
            state.value.bikefinderLinks = bf.links;
        }
    },
    setBikes(bikes: BikeFinderQuery_Collection_Products_Items) {
        state.value.bikes = bikes;
        let ranges = [];

        const types = ['original', 'up', ['off', 'off_air'], 'now'];
        const typesSort = [0, 3, 2, 1];
        state.value.bikes = [];
        if (bikes) {
            for (const bike of bikes) {
                if (bike && bike.content && bike.content.category) {
                    // first get the base type of the bike
                    const type = types.filter((p) => {
                        if (Array.isArray(p)) {
                            for (const t of p) {
                                if ((bike.content?.category || []).includes(t)) {
                                    return true;
                                }
                            }
                        } else if ((bike.content?.category || []).includes(p)) {
                            return true;
                        }
                        return false;
                    });
                    if (type && type.length === 1) {
                        const idx = types.indexOf(type[0]);
                        const sort = typesSort[idx];
                        const t = Array.isArray(type[0]) ? type[0].join('_') : type[0];
                        const exists = ranges.find((r) => r.code === t);
                        if (exists) {
                            exists.bikes.push(bike.content.id);
                        } else {
                            ranges.push({
                                code: t,
                                sort,
                                bikes: [bike.content.id],
                            });
                        }

                        state.value.bikes.push({
                            ...bike,
                            range: t,
                        });
                    }
                }
            }
        }
        for (const b of ranges) {
            b.bikes = b.bikes.sort((a, b) => {
                // natural order, remove the id prefix. if the handle starts with woom-, remove that as well
                let a_name = a.substring(a.indexOf('shop_product:') + 13);
                let b_name = b.substring(b.indexOf('shop_product:') + 13);
                if (a_name.indexOf('woom-') === 0) {
                    a_name = a_name.substring(5);
                }
                if (b_name.indexOf('woom-') === 0) {
                    b_name = b_name.substring(5);
                }
                // add a sorting exception for woom-1 and woom-1-plus
                if (a_name.includes('1-plus') && b_name.includes('1')) {
                    return 1;
                } else if (a_name.includes('1') && b_name.includes('1-plus')) {
                    return -1;
                }
                if (a_name < b_name) {
                    return -1;
                } else if (a_name > b_name) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
        // now sort the range
        ranges = ranges.sort((a, b) => {
            return a.sort - b.sort;
        });
        // console.log('ranges and bikes', ranges, bikes)
        state.value.bikesByRange = ranges;
    },

    // setWaitlistCodes(state, codes) {
    //   state.orderCodes = codes;
    //   // console.log(state)
    // },
};

const getters = {
    // getNotifications: (state) => {
    //     return state.notificationQueue;
    // },
    // websiteUpsell: (state) => {
    //     return get(state, 'website.shop.upsell', []);
    // },
    pageLocale: () => {
        if (state.value.country) {
            return `${state.value.lang}_${state.value.country.toUpperCase()}`;
        } else {
            return state.value.lang;
        }
    },
    // // returns a proper language locale
    locale: () => {
        if (process.client && state.value.country) {
            // set the cookie at this point
            let expires = '';
            if (days) {
                const date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = '; expires=' + date.toUTCString();
            }
            document.cookie = 'lang=' + `${state.value.lang}_${state.value.country.toUpperCase()}` + expires + '; path=/';
        }
        if (state.value.country && state.value.country.toLowerCase() !== 'int') {
            return `${state.value.lang}_${state.value.country.toUpperCase()}`;
        } else {
            return state.value.lang;
        }
    },
    seo: (): WebsiteQuery_Website_SEO_NonNullable => {
        const seo = state.value.website?.seo || {
            image: null,
            title: 'woom bikes',
            description: '',
        };

        return seo;
    },
    units: (): UnitsType => {
        const shop = state.value.website?.shop || null;
        if (shop && shop.units) {
            const units = shop.units;
            return {
                metric: units.includes('metric'),
                imperial: units.includes('imperial'),
            };
        }
        return {
            imperial: true,
            metric: true,
        };
    },
    // footer: (state) => {
    //     return get(state.website, 'footer', null);
    // },
    // navbar: (state) => {
    //     return get(state.website, 'navbar', null);
    // },
    // defaultSeo: (state) => {
    //     // return the empty object
    //     return get(state.website, 'seo', {});
    // },
    // socialLinks: (state) => {
    //     return get(state.website, 'social', {});
    // },
    // // returns locale and preview for nested queries (SSR)
    // queryData: (state) => {
    //     return {
    //         locale: `${state.lang}_${state.country.toUpperCase()}`,
    //         preview: state.preview
    //     };
    // },
    hasPageCache: (state: AppStateType, url: string, locale: string) => {
        const ru = state.pageCache?.url.url || null;
        const rl = state.pageCache?.url.locale || null;
        return ru === url && rl === locale;
    },
    // preview: (state) => state.preview,
    // bikefinderLink: (state) => {
    //     // get the link and resolve against the references.
    //     if (state.bikefinder) {
    //         const link = state.bikefinder.link;
    //         if (link) {
    //             if (link.type === 'sitemap' || link.type === 'page') {
    //                 const url = state.bikefinderLinks.find((p) => p.id === link.data);
    //                 if (url) {
    //                     return {
    //                         ...link,
    //                         url: url.url
    //                     };
    //                 }
    //             }
    //             return link;
    //         }
    //     }
    //     return null;
    // },
    // sizeGuideLink: (state) => {
    //     // console.log('sizeguide', state.website)
    //     if (state.website && state.website.shop && state.website.shop.sizeGuide) {
    //         const link = state.website.shop.sizeGuide;
    //         if (link) {
    //             if (link.type === 'sitemap' || link.type === 'page') {
    //                 const url = get(state.website, 'refs.links', []).find((p) => p.id === link.data);
    //                 if (url) {
    //                     return {
    //                         ...link,
    //                         url: url.url
    //                     };
    //                 }
    //             }
    //             return link;
    //         }
    //     }
    //     return null;
    // },
    // howItWorksLink: (state) => {
    //     if (state.bikefinder && state.bikefinder.how_it_works) {
    //         const link = state.bikefinder.how_it_works;
    //         if (link) {
    //             if (link.type === 'sitemap' || link.type === 'page') {
    //                 const url = get(state, 'bikefinderLinks', []).find((p) => p.id === link.data);
    //                 if (url) {
    //                     return {
    //                         ...link,
    //                         url: url.url
    //                     };
    //                 }
    //             }
    //             return link;
    //         }
    //     }
    //     return null;
    // },
    // lang: (state) => state.lang
};

const actions = {
    // headers({ commit }, header) {
    //     commit('headers', header);
    // },
    // setHref({ commit }, href) {
    //     commit('setHref', href);
    // },
    // showRelatedProducts({ commit }, showRelated) {
    //     commit('showRelatedProducts', showRelated);
    // },
    // fetchNotifications({ commit, state }, locale) {
    //     return new Promise((resolve, reject) => {
    //         const current = get(state, 'website.locale', null);
    //         if (Array.isArray(state.notifications) && state.notifications.length > 0 && current === locale) {
    //             resolve();
    //         } else {
    //             const client = this.app.apolloProvider.defaultClient;
    //             if (client) {
    //                 // console.log('load website', locale, state.preview)
    //                 client
    //                     .query({
    //                         query: QUERY_NOTIFICATIONS,
    //                         variables: {
    //                             locale,
    //                             // for now keep preview true
    //                             // TODO: add preview logic to frontend
    //                             preview: state.preview
    //                         }
    //                     })
    //                     .then((response) => {
    //                         // store the website object
    //                         // console.log('got website response', JSON.stringify(get(response, 'data.website.navbar', null), null, ' '))
    //                         const notifications = get(response, 'data.getNotifications', []);
    //                         commit('setNotifications', notifications);
    //                         resolve();
    //                     })
    //                     .catch((err) => {
    //                         console.log('notifications error', err);
    //                         reject(err);
    //                     });
    //             } else {
    //                 reject(new Error('no apollo client defined'));
    //             }
    //         }
    //     });
    // },
    // setPreview({ commit }, preview) {
    //     commit('setPreview', preview);
    //     commit('enqueueNotification', {
    //         type: NotificationType.INFO,
    //         message: 'The Woom Site is in PREVIEW mode',
    //         sticky: false
    //     });
    // },
    // setCI({ commit }, value) {
    //     commit('setCI', value);
    // },
    setLangCountry: async (langCountry: string) => {
        return new Promise<void>(async (resolve, reject) => {
            const lc = {
                lang: 'en',
                country: '',
            };
            if (langCountry) {
                // console.log('langCountry is', langCountry)
                const m = langCountry.match(langCountryMatch);
                // console.log('match', langCountry, m)
                if (m && m[0]) {
                    const p = m[0].toLowerCase();
                    const s = p.split(/[_-]/);
                    if (s.length === 1) {
                        // do we have this language?
                        lc.lang = s[0];
                    } else if (s.length >= 2) {
                        lc.lang = s[0];
                        // do we have this country?
                        lc.country = s[1];
                    }
                } else {
                    lc.lang = langCountry;
                }
                // console.log('langCountry parsed', lc)
            }

            mutations.setLangCountry(lc);

            const validLangCountry = lc.country ? `${lc.lang}-${lc.country}` : lc.lang;
            // now validate lang and country
            try {
                // https://medium.com/@jamiecarter7/getactivepinia-was-called-with-no-active-pinia-after-updating-nuxt3-and-pinia-104b33dce97e
                // https://github.com/vuejs/pinia/discussions/2137
                const i18nStore = useI18nStore();
                await i18nStore.actions.setLocale(validLangCountry as LocaleType);
                resolve();
            } catch (err) {
                reject(new Error('invalid language'));
            }
        });
    },
    enqueueNotification: async (notification: NotificationPayload) => {
        return new Promise<void>((resolve) => {
            mutations.enqueueNotification(notification);
            resolve();
        });
    },
    // dequeueNotification({ commit, state }) {
    //     new Promise((resolve) => {
    //         commit('dequeueNotification');
    //         resolve(state.notificationQueue && state.notificationQueue.length > 0 ? state.notificationQueue[0] : null);
    //     });
    // },
    // setBaseUrl({ commit }, url) {
    //     commit('setBaseUrl', url);
    // },
    checkWebsite: async () => {
        return new Promise<boolean>(async (resolve, reject) => {
            // console.log('checkWebsite called')
            // check if we have a website element stored
            const current = state.value.website?.locale;
            const locale = getters.pageLocale();
            // check if the locales are matching
            if (current !== locale) {
                // fetch the website object
                // console.log('load website', locale)
                mutations.clear();
                try {
                    const data = await getWebsiteQueryData({
                        locale,
                        codename: 'website',
                        preview: state.value.preview,
                    });

                    // store the website object
                    // console.log('got website response', JSON.stringify(get(response, 'data.website.navbar', null), null, ' '))
                    const website = data.website;
                    if (website) {
                        mutations.saveWebsite(website);
                        if (process.client) {
                            // check the cart
                            // TODO-FROM-OLD-SITE
                            // this.dispatch('cart/fetch', { locale });
                        }
                        // now load the bikes
                        try {
                            await actions.bikefinder();
                            resolve(true);
                        } catch (err) {
                            reject(err);
                        }
                    } else {
                        // throw 404
                        // TODO-FROM-OLD-SITE
                        // const $error = get(this, '$nuxtError', null);
                        // if ($error) {
                        //     $error({ statusCode: 404, message: 'Region not found' });
                        //     resolve();
                        // } else {
                        //     reject(new Error('404 error'));
                        // }
                    }
                } catch (err) {
                    console.log('query error', err, JSON.stringify(err, null, '  '));
                    reject(err);
                }
            } else {
                resolve(true);
            }
        });
    },
    // loadMainMenu({ state, dispatch, commit }) {
    //     return new Promise((resolve, reject) => {
    //         if (state && state.main_menu) {
    //             resolve(state.main_menu);
    //         } else {
    //             const id = get(state, 'website.menu', null);
    //             if (id) {
    //                 dispatch('loadMenu', id)
    //                     .then((menu) => {
    //                         commit('saveMenu', menu);
    //                         resolve(menu);
    //                     })
    //                     .catch((err) => {
    //                         reject(err);
    //                     });
    //             } else {
    //                 reject(new Error('no main menu linked to website'));
    //             }
    //         }
    //     });
    // },
    // loadMenu({ state, getters }, id) {
    //     return new Promise((resolve, reject) => {
    //         const locale = getters.pageLocale;
    //         const preview = state.preview;
    //         const client = this.app.apolloProvider.defaultClient;
    //         if (client) {
    //             client
    //                 .query({
    //                     query: QUERY_MENU,
    //                     variables: {
    //                         locale,
    //                         id,
    //                         // for now keep preview true
    //                         // TODO: add preview logic to frontend
    //                         preview
    //                     }
    //                 })
    //                 .then((response) => {
    //                     resolve(get(response, 'data.menu', null));
    //                 })
    //                 .catch((err) => {
    //                     console.log('menu query error', err);
    //                     reject(err);
    //                 });
    //         } else {
    //             reject(new Error('no apollo client found'));
    //         }
    //     });
    // },

    loadPageByUrl: async ({ locale, url }: { locale: string; url: string }): Promise<PageByUrlQuery_PageByUrl> => {
        // return new Promise<PageByUrlQuery_PageByUrl>((resolve, reject) => {
        const preview = state.value.preview;
        mutations.setLangCountry(locale);

        await actions.checkWebsite();

        if (getters.hasPageCache(state.value, url, locale)) {
            console.log('got page in cache', state.value.pageCache);
            return state.value.pageCache;
        } else {
            const data = await getPageByUrlQueryData({
                locale,
                url,
                preview, // get(query, 'preview', 'false') === 'true'
                full: process.server,
            });

            console.log('pageByUrlQuery result data', data);

            const pd: PageByUrlQuery_PageByUrl = data.pageByUrl || null;
            mutations.savePage(pd);
            console.log('pd', pd);
            return pd;
        }
        // })

        // });
    },
    /**
        load the bikefinder collection and store it localy
        */
    bikefinder: async (): Promise<BikeFinderQuery_Collection_Products_Items> => {
        const locale = getters.pageLocale();

        if (state.value.bikefinder) {
            return state.value.bikefinder;
        } else {
            // console.log('current locale is', locale)
            const localeSplit = locale.split('_');

            const bikeFinderId = `${(localeSplit[1] || '').toLowerCase()}:${localeSplit[0].toLowerCase()}:collection:bikefinder`;
            setApolloClient();
            const bikeFinderQuery = useBikefinderQuery({
                id: bikeFinderId,
                locale,
                preview: state.value.preview,
            });
            try {
                const data = await awaitableGQLQuery(bikeFinderQuery);

                // console.log('got bikefinder data', data)

                const b: BikeFinderQuery_BikeFinder = data.data.bikefinder || null;
                if (b) {
                    mutations.setBikefinder(b);
                }
                const d: BikeFinderQuery_Collection = data.data.collection;
                // const d = get(data, 'data.collection', null);
                const bikes: BikeFinderQuery_Collection_Products_Items = d?.products.items;
                // const bikes = get(d, 'products.items', []);
                if (bikes) {
                    mutations.setBikes(bikes);
                    return bikes;
                } else {
                    throw new Error('no collection response data received');
                }
            } catch (err) {
                throw err;
            }
        }
    },
    /**
        load shop info data based on the website relationship on shop.content
        */
    loadShopInfo: async () => {
        return new Promise<LoadShopInfoReturnType>(async (resolve, reject) => {
            const locale = getters.pageLocale();
            const preview = state.value.preview;
            const websiteContent = state.value.website?.shop?.content || null;
            const current = state.value.shopInfo;
            if (current && websiteContent) {
                const shopInfo = current;
                resolve(
                    websiteContent.map((s) => {
                        return shopInfo.find((c) => c.id === s);
                    }),
                );
            } else if (websiteContent) {
                setApolloClient();

                const query = useShopContentLazyQuery({
                    locale,
                    preview,
                    ids: websiteContent,
                });

                try {
                    const data = await awaitableGQLLazyQuery(query);

                    const info = data.data.shopContent || [];
                    mutations.saveShopInfo(info);

                    const output = websiteContent
                        ? websiteContent.map((s) => {
                              return info.find((c) => c.id === s);
                          })
                        : info;

                    resolve(output);
                } catch (err) {
                    reject(err);
                }
            } else {
                // just return the empty array
                resolve([]);
            }
        });
    },
    // loadWaitlistCodes({ commit }, codes) {
    //     commit('setWaitlistCodes', codes);
    // },
    // setMultidomain({ commit }, multidomain) {
    //     commit('setMultidomain', multidomain);
    // }
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
export const useAppStore = defineStore('woom-store-app', () => {
    return { state, getters, mutations, actions };
});
