import { DocumentNode } from 'graphql';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string | number; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    ContentID: { input: any; output: any };
    DateTime: { input: any; output: any };
    Decimal: { input: any; output: any };
    JSON: { input: any; output: any };
    JSONObject: { input: any; output: any };
};

export type Asset = {
    data?: Maybe<Scalars['JSONObject']['output']>;
    id: Scalars['ID']['output'];
};

export type BackInStockInput = {
    accepts_marketing: Scalars['Boolean']['input'];
    customer_utc_offset: Scalars['Int']['input'];
    email: Scalars['String']['input'];
    locale: Scalars['String']['input'];
    productId: Scalars['String']['input'];
    variantId: Scalars['String']['input'];
};

export type BackInStockResponse = {
    data?: Maybe<Scalars['JSONObject']['output']>;
    error?: Maybe<Scalars['JSONObject']['output']>;
    status: Scalars['Int']['output'];
};

export type Bikefinder = {
    bikefinder?: Maybe<Scalars['JSONObject']['output']>;
    id: Scalars['ID']['output'];
    links?: Maybe<Array<Scalars['JSONObject']['output']>>;
    locale: Scalars['String']['output'];
    references?: Maybe<Array<Scalars['String']['output']>>;
    system: SystemInfo;
    types: Array<Scalars['String']['output']>;
    woom: Scalars['JSONObject']['output'];
};

export type Bundle = {
    id: Scalars['ID']['output'];
    products: Array<ProductPreview>;
};

export type BundleContent = {
    cartImage?: Maybe<Asset>;
    code?: Maybe<Scalars['String']['output']>;
    compare_at_price?: Maybe<Scalars['String']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    gallery?: Maybe<Array<Asset>>;
    label?: Maybe<Scalars['String']['output']>;
    lead?: Maybe<Scalars['String']['output']>;
    price?: Maybe<Scalars['String']['output']>;
    products?: Maybe<Scalars['JSONObject']['output']>;
    requirement?: Maybe<Scalars['String']['output']>;
};

export type BundleProductFilterInput = {
    key: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type BundleProductInput = {
    filters: Array<BundleProductFilterInput>;
    handle: Scalars['String']['input'];
};

export type CampaignSearchInput = {
    age?: InputMaybe<Scalars['Float']['input']>;
    campaign: Scalars['String']['input'];
    language?: InputMaybe<Scalars['String']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    pageSize?: InputMaybe<Scalars['Int']['input']>;
    partial?: InputMaybe<Scalars['Boolean']['input']>;
    text?: InputMaybe<Scalars['String']['input']>;
};

export type Checkout = {
    completedAt?: Maybe<Scalars['DateTime']['output']>;
    createdAt: Scalars['DateTime']['output'];
    currencyCode?: Maybe<CurrencyCode>;
    email?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    lineItems: Array<CheckoutLineItem>;
    lineItemsSubtotalPrice?: Maybe<Money>;
    nativeCheckoutId?: Maybe<Scalars['String']['output']>;
    nativeId?: Maybe<Scalars['String']['output']>;
    orderStatusUrl?: Maybe<Scalars['String']['output']>;
    shop?: Maybe<Shop>;
    subtotalPrice?: Maybe<Money>;
    taxExempt: Scalars['Boolean']['output'];
    taxesIncluded: Scalars['Boolean']['output'];
    totalPrice?: Maybe<Money>;
    totalQuantity: Scalars['Int']['output'];
    totalTax?: Maybe<Money>;
    updatedAt: Scalars['DateTime']['output'];
    webUrl: Scalars['String']['output'];
};

export enum CheckoutActionType {
    Add = 'ADD',
    Create = 'CREATE',
    Fetch = 'FETCH',
    Remove = 'REMOVE',
    Replace = 'REPLACE',
    Update = 'UPDATE',
}

export enum CheckoutErrorCode {
    AlreadyCompleted = 'ALREADY_COMPLETED',
    BadDomain = 'BAD_DOMAIN',
    Blank = 'BLANK',
    CartDoesNotMeetDiscountRequirementsNotice = 'CART_DOES_NOT_MEET_DISCOUNT_REQUIREMENTS_NOTICE',
    CustomerAlreadyUsedOncePerCustomerDiscountNotice = 'CUSTOMER_ALREADY_USED_ONCE_PER_CUSTOMER_DISCOUNT_NOTICE',
    DiscountDisabled = 'DISCOUNT_DISABLED',
    DiscountExpired = 'DISCOUNT_EXPIRED',
    DiscountLimitReached = 'DISCOUNT_LIMIT_REACHED',
    DiscountNotFound = 'DISCOUNT_NOT_FOUND',
    Empty = 'EMPTY',
    GiftCardAlreadyApplied = 'GIFT_CARD_ALREADY_APPLIED',
    GiftCardCodeInvalid = 'GIFT_CARD_CODE_INVALID',
    GiftCardCurrencyMismatch = 'GIFT_CARD_CURRENCY_MISMATCH',
    GiftCardDepleted = 'GIFT_CARD_DEPLETED',
    GiftCardDisabled = 'GIFT_CARD_DISABLED',
    GiftCardExpired = 'GIFT_CARD_EXPIRED',
    GiftCardNotFound = 'GIFT_CARD_NOT_FOUND',
    GiftCardUnusable = 'GIFT_CARD_UNUSABLE',
    GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
    Invalid = 'INVALID',
    InvalidForCountry = 'INVALID_FOR_COUNTRY',
    InvalidForCountryAndProvince = 'INVALID_FOR_COUNTRY_AND_PROVINCE',
    InvalidProvinceInCountry = 'INVALID_PROVINCE_IN_COUNTRY',
    InvalidRegionInCountry = 'INVALID_REGION_IN_COUNTRY',
    InvalidStateInCountry = 'INVALID_STATE_IN_COUNTRY',
    LessThan = 'LESS_THAN',
    LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
    LineItemNotFound = 'LINE_ITEM_NOT_FOUND',
    Locked = 'LOCKED',
    MissingPaymentInput = 'MISSING_PAYMENT_INPUT',
    NotEnoughInStock = 'NOT_ENOUGH_IN_STOCK',
    NotSupported = 'NOT_SUPPORTED',
    Present = 'PRESENT',
    ServiceError = 'SERVICE_ERROR',
    ServiceValidationError = 'SERVICE_VALIDATION_ERROR',
    ShippingRateExpired = 'SHIPPING_RATE_EXPIRED',
    TooLong = 'TOO_LONG',
    TotalPriceMismatch = 'TOTAL_PRICE_MISMATCH',
    UnableToApply = 'UNABLE_TO_APPLY',
}

export type CheckoutLineItem = {
    customAttributes?: Maybe<Scalars['JSON']['output']>;
    discountAllocations?: Maybe<Scalars['JSON']['output']>;
    id?: Maybe<Scalars['ID']['output']>;
    nativeProductId?: Maybe<Scalars['String']['output']>;
    quantity?: Maybe<Scalars['Int']['output']>;
    shopTitle?: Maybe<Scalars['String']['output']>;
    unitPrice?: Maybe<Money>;
    variant?: Maybe<ProductVariant>;
};

export type CheckoutLineItemInput = {
    id?: InputMaybe<Scalars['ID']['input']>;
    props?: InputMaybe<Scalars['JSON']['input']>;
    quantity?: InputMaybe<Scalars['Int']['input']>;
    variantId?: InputMaybe<Scalars['ID']['input']>;
};

export type CheckoutPayload = {
    checkout?: Maybe<Checkout>;
    userErrors: Array<CheckoutUserError>;
};

export type CheckoutUserError = {
    code?: Maybe<CheckoutErrorCode>;
    field?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    message: Scalars['String']['output'];
};

export type Cms = {
    defaultLanguage: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    languages: Array<Scalars['String']['output']>;
    name: Scalars['String']['output'];
};

export type CmsBundle = {
    availableForSale: Scalars['Boolean']['output'];
    cartCode?: Maybe<Scalars['String']['output']>;
    cartImage?: Maybe<Asset>;
    cartLabel?: Maybe<Scalars['String']['output']>;
    compareAtPrice?: Maybe<Scalars['String']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    filter: Scalars['String']['output'];
    hero?: Maybe<Asset>;
    id: Scalars['ID']['output'];
    language: Scalars['String']['output'];
    lastModified: Scalars['DateTime']['output'];
    leadProducts?: Maybe<Array<Scalars['String']['output']>>;
    locale: Scalars['String']['output'];
    numProducts: Scalars['Int']['output'];
    price?: Maybe<Scalars['String']['output']>;
    products: Array<ProductPreview>;
    requiresConfiguration: Scalars['Boolean']['output'];
    subtitle?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    url: Scalars['String']['output'];
};

export type CmsItem = {
    id: Scalars['ID']['output'];
    language: Scalars['String']['output'];
    lastModified: Scalars['DateTime']['output'];
    name: Scalars['String']['output'];
    project: Scalars['String']['output'];
    projectId: Scalars['String']['output'];
    published: Scalars['Boolean']['output'];
    seo?: Maybe<Seo>;
    title?: Maybe<Scalars['String']['output']>;
    types: Array<Scalars['String']['output']>;
};

export type CmsSearchFilter = {
    published?: InputMaybe<Scalars['Boolean']['input']>;
    text?: InputMaybe<Scalars['String']['input']>;
    types?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CollectionInfo = {
    handle: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    nativeId: Scalars['String']['output'];
    title?: Maybe<Scalars['String']['output']>;
};

export type ContentPage = {
    base?: Maybe<Scalars['JSONObject']['output']>;
    breadcrumbs?: Maybe<Array<Scalars['JSONObject']['output']>>;
    bundle?: Maybe<BundleContent>;
    content?: Maybe<Scalars['JSONObject']['output']>;
    product?: Maybe<Product>;
};

export type CrmInput = {
    config: Scalars['JSONObject']['input'];
    locale: Scalars['String']['input'];
    payload: Scalars['JSONObject']['input'];
    type: Scalars['String']['input'];
};

export type CrmResponse = {
    data?: Maybe<Scalars['JSONObject']['output']>;
    error?: Maybe<Scalars['JSONObject']['output']>;
    status: Scalars['Int']['output'];
};

export enum CurrencyCode {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Chf = 'CHF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Crc = 'CRC',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Egp = 'EGP',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jep = 'JEP',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Ltl = 'LTL',
    Lvl = 'LVL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Sll = 'SLL',
    Srd = 'SRD',
    Ssp = 'SSP',
    Std = 'STD',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Uyu = 'UYU',
    Uzs = 'UZS',
    Vef = 'VEF',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xcd = 'XCD',
    Xof = 'XOF',
    Xpf = 'XPF',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
}

export type FeedItem = {
    ageGroup: Scalars['String']['output'];
    category: Scalars['Int']['output'];
    description?: Maybe<Scalars['String']['output']>;
    html_description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    language: Scalars['String']['output'];
    locale: Scalars['String']['output'];
    shopify?: Maybe<Scalars['String']['output']>;
    url: Scalars['String']['output'];
};

export type FeedProduct = {
    handle: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    nativeId: Scalars['String']['output'];
    shopTitle?: Maybe<Scalars['String']['output']>;
    variants?: Maybe<FeedVariants>;
};

export type FeedVariant = {
    availabilityState: ProductAvailability;
    availableForSale?: Maybe<Scalars['Boolean']['output']>;
    barcode?: Maybe<Scalars['String']['output']>;
    compareAtPrice?: Maybe<Money>;
    currentlyNotInStock?: Maybe<Scalars['Boolean']['output']>;
    feedTitle?: Maybe<Scalars['String']['output']>;
    forSale: Scalars['Boolean']['output'];
    hidden: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    locked: Scalars['Boolean']['output'];
    nativeId?: Maybe<Scalars['String']['output']>;
    notifyMe: Scalars['Boolean']['output'];
    orderCode?: Maybe<Scalars['String']['output']>;
    preOrder: Scalars['Boolean']['output'];
    price?: Maybe<Money>;
    productRef?: Maybe<Scalars['String']['output']>;
    productType?: Maybe<Scalars['String']['output']>;
    quantityAvailable?: Maybe<Scalars['Int']['output']>;
    requiresShipping?: Maybe<Scalars['Boolean']['output']>;
    selectedOptions?: Maybe<Array<Maybe<SelectedProductOption>>>;
    shippingDate?: Maybe<Scalars['DateTime']['output']>;
    shop: Shop;
    shopImage?: Maybe<Scalars['String']['output']>;
    sku?: Maybe<Scalars['String']['output']>;
    weight?: Maybe<Scalars['Float']['output']>;
    weightUnit?: Maybe<Scalars['String']['output']>;
};

export type FeedVariants = {
    items?: Maybe<Array<Maybe<FeedVariant>>>;
    total?: Maybe<Scalars['Int']['output']>;
};

export type GdgKmInput = {
    code?: InputMaybe<Scalars['String']['input']>;
    date: Scalars['String']['input'];
    imperial?: InputMaybe<Scalars['Boolean']['input']>;
    token: Scalars['String']['input'];
    total: Scalars['Float']['input'];
    tripItems: Array<TripItemInput>;
    userKm: Scalars['Float']['input'];
};

export type GdgRegister = {
    config: Scalars['JSONObject']['input'];
    locale: Scalars['String']['input'];
    payload: Scalars['JSONObject']['input'];
    recaptcha: Scalars['String']['input'];
    type: Scalars['String']['input'];
};

export type GdgResponse = {
    data?: Maybe<Scalars['JSONObject']['output']>;
    error?: Maybe<Scalars['JSONObject']['output']>;
    status: Scalars['Int']['output'];
};

export type GdgStream = {
    crew: Scalars['Int']['output'];
    date: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
    limit: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    total: Scalars['Float']['output'];
};

export type GdgUserData = {
    before: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    latest?: Maybe<Scalars['JSONObject']['output']>;
    name: Scalars['String']['output'];
    total: Scalars['Float']['output'];
};

export type GdgUserResponse = {
    data?: Maybe<GdgUserData>;
    error?: Maybe<Scalars['JSONObject']['output']>;
    status: Scalars['Int']['output'];
};

export type HubspotForm = {
    elements: Scalars['JSONObject']['output'];
    id: Scalars['String']['output'];
};

export type HubspotFormSummary = {
    guid: Scalars['String']['output'];
    name: Scalars['String']['output'];
    portalId: Scalars['String']['output'];
};

export type KlaviyoBackInStockInput = {
    email: Scalars['String']['input'];
    productId: Scalars['String']['input'];
    variantId: Scalars['String']['input'];
};

export type LanguageSwitcher = {
    copy?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    image?: Maybe<Asset>;
    regions: Array<WoomRegion>;
    seo?: Maybe<Seo>;
    sequence: Array<Scalars['String']['output']>;
    system: SystemInfo;
    title?: Maybe<Scalars['String']['output']>;
};

export type LocaleFilterInput = {
    countries?: InputMaybe<Array<Scalars['String']['input']>>;
    filter?: InputMaybe<SitemapFilter>;
    languages?: InputMaybe<Array<Scalars['String']['input']>>;
    locales?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Lookup = {
    id: Scalars['ID']['output'];
    system: SystemInfo;
    woom: Scalars['JSONObject']['output'];
};

export type Menu = {
    id: Scalars['ID']['output'];
    items?: Maybe<Array<Scalars['String']['output']>>;
    locale?: Maybe<Scalars['String']['output']>;
    references?: Maybe<Array<MenuItem>>;
    title?: Maybe<Scalars['String']['output']>;
};

export type MenuItem = {
    color?: Maybe<Scalars['String']['output']>;
    depth?: Maybe<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    items?: Maybe<Array<Scalars['String']['output']>>;
    link?: Maybe<Scalars['JSONObject']['output']>;
    title: Scalars['String']['output'];
    type?: Maybe<Scalars['String']['output']>;
};

export type Money = {
    amount: Scalars['String']['output'];
    currencyCode: CurrencyCode;
};

export type Mutation = {
    backInStockKlaviyoSubmit?: Maybe<BackInStockResponse>;
    backInStockSubmit?: Maybe<BackInStockResponse>;
    crmSubmit?: Maybe<CrmResponse>;
    csSubmit?: Maybe<CrmResponse>;
    findMyBike: Scalars['String']['output'];
    gdgFetchUser: GdgUserResponse;
    gdgFetchUserData: Array<Scalars['JSONObject']['output']>;
    gdgKm?: Maybe<GdgResponse>;
    gdgLatest: Array<GdgStream>;
    gdgRegistration?: Maybe<GdgResponse>;
    gdgResendLink?: Maybe<GdgResponse>;
    gdgStatus: GdgResponse;
    hubspotFetch: Array<HubspotForm>;
    manageCheckout?: Maybe<CheckoutPayload>;
    productAvailability?: Maybe<RealTimeProduct>;
    productStatus?: Maybe<Scalars['JSONObject']['output']>;
    shopifyBulk?: Maybe<Scalars['JSON']['output']>;
    shopifyBulkStatus?: Maybe<Scalars['JSON']['output']>;
};

export type MutationBackInStockKlaviyoSubmitArgs = {
    input: KlaviyoBackInStockInput;
};

export type MutationBackInStockSubmitArgs = {
    input: BackInStockInput;
};

export type MutationCrmSubmitArgs = {
    input: CrmInput;
};

export type MutationCsSubmitArgs = {
    input: CrmInput;
};

export type MutationFindMyBikeArgs = {
    age: Scalars['String']['input'];
    gender?: InputMaybe<Scalars['String']['input']>;
    height: Scalars['String']['input'];
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    skill?: InputMaybe<Scalars['String']['input']>;
};

export type MutationGdgFetchUserArgs = {
    id: Scalars['String']['input'];
};

export type MutationGdgFetchUserDataArgs = {
    token: Scalars['String']['input'];
};

export type MutationGdgKmArgs = {
    input: GdgKmInput;
};

export type MutationGdgRegistrationArgs = {
    input: GdgRegister;
};

export type MutationGdgResendLinkArgs = {
    input: GdgRegister;
};

export type MutationManageCheckoutArgs = {
    action: CheckoutActionType;
    checkoutId?: InputMaybe<Scalars['ID']['input']>;
    lineItems?: InputMaybe<Array<InputMaybe<CheckoutLineItemInput>>>;
    locale: Scalars['String']['input'];
};

export type MutationProductAvailabilityArgs = {
    id: Scalars['ID']['input'];
};

export type MutationProductStatusArgs = {
    project: Scalars['String']['input'];
};

export type MutationShopifyBulkArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
    query: Scalars['String']['input'];
    target: Scalars['String']['input'];
    token: Scalars['String']['input'];
};

export type MutationShopifyBulkStatusArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
    target: Scalars['String']['input'];
    token: Scalars['String']['input'];
};

export type Notification = {
    content?: Maybe<Scalars['JSONObject']['output']>;
    end?: Maybe<Scalars['DateTime']['output']>;
    icon?: Maybe<Asset>;
    id: Scalars['ID']['output'];
    link?: Maybe<Scalars['JSONObject']['output']>;
    locale: Scalars['String']['output'];
    mobile_text?: Maybe<Scalars['JSONObject']['output']>;
    start?: Maybe<Scalars['DateTime']['output']>;
    text?: Maybe<Scalars['JSONObject']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['String']['output']>;
    types?: Maybe<Array<Scalars['String']['output']>>;
    weighting?: Maybe<Scalars['Int']['output']>;
};

export type Page = {
    cannonical?: Maybe<Array<PageCannonical>>;
    id: Scalars['ID']['output'];
    locale?: Maybe<Scalars['String']['output']>;
    page: ContentPage;
    refs: PageReferences;
    seo?: Maybe<Seo>;
    system: Scalars['JSONObject']['output'];
    types: Array<Scalars['String']['output']>;
    url?: Maybe<Scalars['JSONObject']['output']>;
    woom: Scalars['JSONObject']['output'];
};

export type PageCannonical = {
    language: Scalars['String']['output'];
    locale: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type PageReferences = {
    authors: Array<Scalars['JSONObject']['output']>;
    links: Array<Scalars['JSONObject']['output']>;
    references: Array<Scalars['JSONObject']['output']>;
    tags: Array<Scalars['JSONObject']['output']>;
};

export type Pagination = {
    first?: Maybe<Scalars['String']['output']>;
    hasNext: Scalars['Boolean']['output'];
    hasPrevious: Scalars['Boolean']['output'];
    last?: Maybe<Scalars['String']['output']>;
};

export type PaginationInput = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    size?: InputMaybe<Scalars['Int']['input']>;
};

export type PriceRange = {
    maxVariantPrice: Money;
    minVariantPrice: Money;
};

export type Product = {
    affirm: Scalars['Boolean']['output'];
    availableForSale?: Maybe<Scalars['Boolean']['output']>;
    category?: Maybe<Array<Scalars['String']['output']>>;
    collections: Array<Maybe<CollectionInfo>>;
    customNaming: Scalars['Boolean']['output'];
    description?: Maybe<Scalars['String']['output']>;
    features?: Maybe<Scalars['String']['output']>;
    forSale: Scalars['Boolean']['output'];
    handle: Scalars['String']['output'];
    hero?: Maybe<Asset>;
    id: Scalars['ID']['output'];
    labels: Array<Maybe<ProductLabel>>;
    limit: Scalars['Int']['output'];
    max_age?: Maybe<Scalars['Float']['output']>;
    max_height?: Maybe<Scalars['JSONObject']['output']>;
    min_age?: Maybe<Scalars['Float']['output']>;
    min_height?: Maybe<Scalars['JSONObject']['output']>;
    nativeId: Scalars['String']['output'];
    notifyMe: Scalars['Boolean']['output'];
    onlySingleProduct: Scalars['Boolean']['output'];
    options?: Maybe<Array<Maybe<ProductOption>>>;
    overview?: Maybe<Asset>;
    preOrder: Scalars['Boolean']['output'];
    priceRange?: Maybe<PriceRange>;
    productType?: Maybe<Scalars['String']['output']>;
    promo?: Maybe<PromoInfo>;
    shop: Shop;
    shopImage?: Maybe<Scalars['String']['output']>;
    shopTitle?: Maybe<Scalars['String']['output']>;
    singleBuy?: Maybe<ProductSingleBuy>;
    tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    title?: Maybe<Scalars['String']['output']>;
    totalInventory?: Maybe<Scalars['Int']['output']>;
    types?: Maybe<Array<Scalars['String']['output']>>;
    variants?: Maybe<ProductVariants>;
    weight?: Maybe<Scalars['JSONObject']['output']>;
    wheels?: Maybe<Scalars['Float']['output']>;
};

export type ProductLabelsArgs = {
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ProductAvailability {
    Available = 'AVAILABLE',
    Comingsoon = 'COMINGSOON',
    Locked = 'LOCKED',
    NotInThisRegion = 'NOT_IN_THIS_REGION',
    OutOfStock = 'OUT_OF_STOCK',
    Preorder = 'PREORDER',
}

export enum ProductCollectionSortKey {
    BestSelling = 'BEST_SELLING',
    CollectionDefault = 'COLLECTION_DEFAULT',
    Created = 'CREATED',
    Id = 'ID',
    Manual = 'MANUAL',
    Price = 'PRICE',
    Relevance = 'RELEVANCE',
    Title = 'TITLE',
}

export type ProductLabel = {
    code: Scalars['String']['output'];
    color?: Maybe<Scalars['String']['output']>;
    hide: Array<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    label?: Maybe<Scalars['String']['output']>;
};

export type ProductOption = {
    id?: Maybe<Scalars['ID']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ProductPageDataPreview = {
    base?: Maybe<Scalars['JSONObject']['output']>;
    breadcrumbs?: Maybe<Array<Scalars['JSONObject']['output']>>;
    content?: Maybe<Scalars['JSONObject']['output']>;
    product?: Maybe<Scalars['JSONObject']['output']>;
};

export type ProductPagePreview = {
    id: Scalars['ID']['output'];
    page: ProductPageDataPreview;
    refs: PageReferences;
    seo?: Maybe<Seo>;
    system: Scalars['JSONObject']['output'];
    types: Array<Scalars['String']['output']>;
    woom: Scalars['JSONObject']['output'];
};

export type ProductPagination = Pagination & {
    first?: Maybe<Scalars['String']['output']>;
    hasNext: Scalars['Boolean']['output'];
    hasPrevious: Scalars['Boolean']['output'];
    items: Array<ProductPreview>;
    last?: Maybe<Scalars['String']['output']>;
};

export type ProductPreview = {
    affirm: Scalars['Boolean']['output'];
    availableForSale?: Maybe<Scalars['Boolean']['output']>;
    collections: Array<Maybe<CollectionInfo>>;
    content?: Maybe<ProductPreviewContent>;
    handle: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    labels: Array<Maybe<ProductLabel>>;
    limit: Scalars['Int']['output'];
    nativeId: Scalars['String']['output'];
    options?: Maybe<Array<Maybe<ProductOption>>>;
    priceRange?: Maybe<PriceRange>;
    productType?: Maybe<Scalars['String']['output']>;
    promo?: Maybe<PromoInfo>;
    shop: Shop;
    shopImage?: Maybe<Scalars['String']['output']>;
    shopTitle?: Maybe<Scalars['String']['output']>;
    singleBuy?: Maybe<ProductSingleBuy>;
    tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    totalInventory?: Maybe<Scalars['Int']['output']>;
    variants?: Maybe<ProductVariants>;
};

export type ProductPreviewContentArgs = {
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductPreviewLabelsArgs = {
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductPreviewContent = {
    category?: Maybe<Array<Scalars['String']['output']>>;
    colors?: Maybe<Scalars['String']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    features?: Maybe<Scalars['String']['output']>;
    hero?: Maybe<Asset>;
    hover?: Maybe<Asset>;
    id: Scalars['ID']['output'];
    max_age?: Maybe<Scalars['Float']['output']>;
    max_height?: Maybe<Scalars['JSONObject']['output']>;
    min_age?: Maybe<Scalars['Float']['output']>;
    min_height?: Maybe<Scalars['JSONObject']['output']>;
    overview?: Maybe<Asset>;
    title?: Maybe<Scalars['String']['output']>;
    types?: Maybe<Array<Scalars['String']['output']>>;
    url?: Maybe<Scalars['JSONObject']['output']>;
    weight?: Maybe<Scalars['JSONObject']['output']>;
    wheels?: Maybe<Scalars['Float']['output']>;
};

export type ProductSingleBuy = {
    availabilityDate?: Maybe<Scalars['DateTime']['output']>;
    availabilityState: Scalars['String']['output'];
    availableForSale?: Maybe<Scalars['Boolean']['output']>;
    barcode?: Maybe<Scalars['String']['output']>;
    customNaming?: Maybe<Scalars['Boolean']['output']>;
    hasMore?: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    nativeId: Scalars['String']['output'];
    shippingDate?: Maybe<Scalars['DateTime']['output']>;
    sku?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    totalInventory?: Maybe<Scalars['Int']['output']>;
};

export type ProductVariant = {
    availabilityDate?: Maybe<Scalars['DateTime']['output']>;
    availabilityState: ProductAvailability;
    availableForSale?: Maybe<Scalars['Boolean']['output']>;
    barcode?: Maybe<Scalars['String']['output']>;
    compareAtPrice?: Maybe<Money>;
    content?: Maybe<ProductVariantContent>;
    currentlyNotInStock?: Maybe<Scalars['Boolean']['output']>;
    customNaming: Scalars['Boolean']['output'];
    feedTitle?: Maybe<Scalars['String']['output']>;
    forSale: Scalars['Boolean']['output'];
    hidden: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    limit: Scalars['Int']['output'];
    locked: Scalars['Boolean']['output'];
    nativeId?: Maybe<Scalars['String']['output']>;
    notifyMe: Scalars['Boolean']['output'];
    orderCode?: Maybe<Scalars['String']['output']>;
    preOrder: Scalars['Boolean']['output'];
    price?: Maybe<Money>;
    productRef?: Maybe<Scalars['String']['output']>;
    productType?: Maybe<Scalars['String']['output']>;
    quantityAvailable?: Maybe<Scalars['Int']['output']>;
    requiresShipping?: Maybe<Scalars['Boolean']['output']>;
    selectedOptions?: Maybe<Array<Maybe<SelectedProductOption>>>;
    shippingDate?: Maybe<Scalars['DateTime']['output']>;
    shop: Shop;
    shopImage?: Maybe<Scalars['String']['output']>;
    shopTitle?: Maybe<Scalars['String']['output']>;
    sku?: Maybe<Scalars['String']['output']>;
    unitPrice?: Maybe<Money>;
    weight?: Maybe<Scalars['Float']['output']>;
    weightUnit?: Maybe<Scalars['String']['output']>;
};

export type ProductVariantContentArgs = {
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductVariantContent = {
    gallery?: Maybe<Array<Asset>>;
    id: Scalars['ID']['output'];
    types?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProductVariants = {
    items?: Maybe<Array<Maybe<ProductVariant>>>;
    total?: Maybe<Scalars['Int']['output']>;
};

export type PromoInfo = {
    banner: Scalars['String']['output'];
    content?: Maybe<Scalars['String']['output']>;
    promo: Scalars['ID']['output'];
};

export type Query = {
    allColors?: Maybe<Array<WoomColor>>;
    bikefinder?: Maybe<Bikefinder>;
    bundle?: Maybe<Bundle>;
    bundles?: Maybe<Array<CmsBundle>>;
    collection?: Maybe<ShopCollection>;
    collections?: Maybe<ShopCollectionPagination>;
    countryByLanguage: Array<Scalars['String']['output']>;
    feedProduct?: Maybe<FeedProduct>;
    fetchTeaser: TeaserResponse;
    getNotifications?: Maybe<Array<WoomNotification>>;
    getSitemap?: Maybe<Sitemap>;
    getUserEntry?: Maybe<UserEntry>;
    hubspotForm: HubspotForm;
    hubspotFormSummaries: Array<HubspotFormSummary>;
    hubspotForms: Array<HubspotForm>;
    languageSwitcher?: Maybe<LanguageSwitcher>;
    listCms: Array<Cms>;
    lookup?: Maybe<Array<Lookup>>;
    lookupCms: Array<CmsItem>;
    menu?: Maybe<Menu>;
    pageById?: Maybe<Page>;
    pageByUrl?: Maybe<Page>;
    pageByUrlOld?: Maybe<Page>;
    product?: Maybe<ShopProduct>;
    productContentById?: Maybe<ProductPagePreview>;
    productFeed: Array<FeedItem>;
    productTags?: Maybe<StringPagination>;
    productTypes?: Maybe<StringPagination>;
    products?: Maybe<ShopProductPagination>;
    recommendCountry: WoomCountry;
    recommendedProducts: Array<ProductPreview>;
    searchCampaign: UserEntryResult;
    shopContent: Array<ShopContent>;
    shopProduct?: Maybe<ProductPreview>;
    shopVariant?: Maybe<ShopVariantResponse>;
    shopify?: Maybe<Scalars['JSON']['output']>;
    shops: Array<Shop>;
    validateUrl: Array<Lookup>;
    version: Version;
    website?: Maybe<Website>;
    woomCountries: Array<WoomCountry>;
};

export type QueryAllColorsArgs = {
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBikefinderArgs = {
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBundleArgs = {
    id: Scalars['ID']['input'];
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    products: Array<InputMaybe<BundleProductInput>>;
};

export type QueryBundlesArgs = {
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCollectionArgs = {
    handle?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
    locale?: InputMaybe<Scalars['String']['input']>;
    pagination?: InputMaybe<PaginationInput>;
    productSortKey?: InputMaybe<ProductCollectionSortKey>;
};

export type QueryCollectionsArgs = {
    locale: Scalars['String']['input'];
    pagination?: InputMaybe<PaginationInput>;
    query?: InputMaybe<Scalars['String']['input']>;
    sortKey?: InputMaybe<ShopCollectionSortKey>;
};

export type QueryCountryByLanguageArgs = {
    lang: Scalars['String']['input'];
    projectId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFeedProductArgs = {
    id: Scalars['ID']['input'];
};

export type QueryFetchTeaserArgs = {
    filters?: InputMaybe<TeaserFilterInput>;
    id: Scalars['String']['input'];
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    references: Array<Scalars['String']['input']>;
};

export type QueryGetNotificationsArgs = {
    date?: InputMaybe<Scalars['String']['input']>;
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetSitemapArgs = {
    codename?: InputMaybe<Scalars['String']['input']>;
    depth?: InputMaybe<Scalars['Int']['input']>;
    id?: InputMaybe<Scalars['ContentID']['input']>;
    locales: LocaleFilterInput;
    reverse?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetUserEntryArgs = {
    id: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHubspotFormArgs = {
    handle: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHubspotFormSummariesArgs = {
    lang: Scalars['String']['input'];
};

export type QueryHubspotFormsArgs = {
    lang: Scalars['String']['input'];
};

export type QueryLanguageSwitcherArgs = {
    codename?: InputMaybe<Scalars['String']['input']>;
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryLookupArgs = {
    language: Array<Scalars['String']['input']>;
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    projectId: Scalars['String']['input'];
    query?: InputMaybe<Scalars['String']['input']>;
    type: Array<Scalars['String']['input']>;
};

export type QueryLookupCmsArgs = {
    filter?: InputMaybe<CmsSearchFilter>;
    ids?: InputMaybe<Array<Scalars['String']['input']>>;
    languages: Array<Scalars['String']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    projectIds?: InputMaybe<Array<Scalars['String']['input']>>;
    projects?: InputMaybe<Array<Scalars['String']['input']>>;
    type: Scalars['String']['input'];
};

export type QueryMenuArgs = {
    codename?: InputMaybe<Scalars['String']['input']>;
    depth?: InputMaybe<Scalars['Int']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPageByIdArgs = {
    id: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPageByUrlArgs = {
    full?: InputMaybe<Scalars['Boolean']['input']>;
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    url: Scalars['String']['input'];
};

export type QueryPageByUrlOldArgs = {
    full?: InputMaybe<Scalars['Boolean']['input']>;
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    url: Scalars['String']['input'];
};

export type QueryProductArgs = {
    id: Scalars['ID']['input'];
};

export type QueryProductContentByIdArgs = {
    id: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryProductFeedArgs = {
    feed: Scalars['String']['input'];
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryProductTagsArgs = {
    locale: Scalars['String']['input'];
    size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProductTypesArgs = {
    locale: Scalars['String']['input'];
    size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProductsArgs = {
    locale: Scalars['String']['input'];
    pagination?: InputMaybe<PaginationInput>;
    query?: InputMaybe<Scalars['String']['input']>;
    sortKey?: InputMaybe<ShopProductSortKey>;
};

export type QueryRecommendedProductsArgs = {
    id: Scalars['ID']['input'];
    locale: Scalars['String']['input'];
};

export type QuerySearchCampaignArgs = {
    input: CampaignSearchInput;
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryShopContentArgs = {
    ids: Array<Scalars['String']['input']>;
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryShopProductArgs = {
    id: Scalars['ID']['input'];
};

export type QueryShopVariantArgs = {
    options: Array<InputMaybe<ShopVariantOptions>>;
    productId: Scalars['ID']['input'];
};

export type QueryShopifyArgs = {
    query: Scalars['String']['input'];
    target: Scalars['String']['input'];
    token: Scalars['String']['input'];
    variables: Scalars['JSON']['input'];
};

export type QueryValidateUrlArgs = {
    locales: Array<Scalars['String']['input']>;
    type?: InputMaybe<UrlType>;
    url: Scalars['String']['input'];
};

export type QueryWebsiteArgs = {
    codename?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RealTimeProduct = {
    affirm: Scalars['Boolean']['output'];
    availableForSale?: Maybe<Scalars['Boolean']['output']>;
    customNaming: Scalars['Boolean']['output'];
    forSale: Scalars['Boolean']['output'];
    handle: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    limit: Scalars['Int']['output'];
    nativeId: Scalars['String']['output'];
    notifyMe: Scalars['Boolean']['output'];
    preOrder: Scalars['Boolean']['output'];
    promo?: Maybe<PromoInfo>;
    totalInventory?: Maybe<Scalars['Int']['output']>;
    variants?: Maybe<RealTimeVariants>;
};

export type RealTimeVariant = {
    availabilityDate?: Maybe<Scalars['DateTime']['output']>;
    availabilityState: ProductAvailability;
    availableForSale?: Maybe<Scalars['Boolean']['output']>;
    barcode?: Maybe<Scalars['String']['output']>;
    currentlyNotInStock?: Maybe<Scalars['Boolean']['output']>;
    customNaming: Scalars['Boolean']['output'];
    feedTitle?: Maybe<Scalars['String']['output']>;
    forSale: Scalars['Boolean']['output'];
    hidden: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    limit: Scalars['Int']['output'];
    locked: Scalars['Boolean']['output'];
    nativeId?: Maybe<Scalars['String']['output']>;
    notifyMe: Scalars['Boolean']['output'];
    orderCode?: Maybe<Scalars['String']['output']>;
    preOrder: Scalars['Boolean']['output'];
    quantityAvailable?: Maybe<Scalars['Int']['output']>;
    shippingDate?: Maybe<Scalars['DateTime']['output']>;
    sku?: Maybe<Scalars['String']['output']>;
};

export type RealTimeVariants = {
    items?: Maybe<Array<Maybe<RealTimeVariant>>>;
    total?: Maybe<Scalars['Int']['output']>;
};

export type Seo = {
    description?: Maybe<Scalars['String']['output']>;
    image?: Maybe<Asset>;
    title?: Maybe<Scalars['String']['output']>;
};

export type SelectedProductOption = {
    name?: Maybe<Scalars['String']['output']>;
    value?: Maybe<Scalars['String']['output']>;
};

export type Shop = {
    adminDomain?: Maybe<Scalars['String']['output']>;
    defaultLanguage?: Maybe<Scalars['String']['output']>;
    domain: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    languages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ShopCollection = {
    description?: Maybe<Scalars['String']['output']>;
    handle: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    nativeId: Scalars['ID']['output'];
    products: ProductPagination;
    shop?: Maybe<Shop>;
    title: Scalars['String']['output'];
};

export type ShopCollectionPagination = Pagination & {
    first?: Maybe<Scalars['String']['output']>;
    hasNext: Scalars['Boolean']['output'];
    hasPrevious: Scalars['Boolean']['output'];
    items: Array<ShopCollection>;
    last?: Maybe<Scalars['String']['output']>;
};

export enum ShopCollectionSortKey {
    Id = 'ID',
    Relevance = 'RELEVANCE',
    Title = 'TITLE',
    UpdatedAt = 'UPDATED_AT',
}

export type ShopContent = {
    code?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    links?: Maybe<Array<Scalars['String']['output']>>;
    locale: Scalars['String']['output'];
    text: Scalars['JSONObject']['output'];
    title?: Maybe<Scalars['String']['output']>;
    types: Array<Scalars['String']['output']>;
};

export type ShopProduct = {
    affirm: Scalars['Boolean']['output'];
    availableForSale?: Maybe<Scalars['Boolean']['output']>;
    collections: Array<Maybe<CollectionInfo>>;
    customNaming: Scalars['Boolean']['output'];
    forSale: Scalars['Boolean']['output'];
    handle: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    labels: Array<Maybe<ProductLabel>>;
    limit: Scalars['Int']['output'];
    nativeId: Scalars['String']['output'];
    notifyMe: Scalars['Boolean']['output'];
    onlySingleProduct: Scalars['Boolean']['output'];
    options?: Maybe<Array<Maybe<ProductOption>>>;
    preOrder: Scalars['Boolean']['output'];
    priceRange?: Maybe<PriceRange>;
    productType?: Maybe<Scalars['String']['output']>;
    promo?: Maybe<PromoInfo>;
    shop: Shop;
    shopImage?: Maybe<Scalars['String']['output']>;
    shopTitle?: Maybe<Scalars['String']['output']>;
    singleBuy?: Maybe<ProductSingleBuy>;
    tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    totalInventory?: Maybe<Scalars['Int']['output']>;
    variants?: Maybe<ProductVariants>;
};

export type ShopProductLabelsArgs = {
    preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShopProductPagination = Pagination & {
    first?: Maybe<Scalars['String']['output']>;
    hasNext: Scalars['Boolean']['output'];
    hasPrevious: Scalars['Boolean']['output'];
    items: Array<ShopProduct>;
    last?: Maybe<Scalars['String']['output']>;
};

export enum ShopProductSortKey {
    BestSelling = 'BEST_SELLING',
    CreatedAt = 'CREATED_AT',
    Id = 'ID',
    Price = 'PRICE',
    ProductType = 'PRODUCT_TYPE',
    Relevance = 'RELEVANCE',
    Title = 'TITLE',
    UpdatedAt = 'UPDATED_AT',
    Vendor = 'VENDOR',
}

export type ShopVariantOptions = {
    option: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type ShopVariantResponse = {
    product: ProductPreview;
    variant: ProductVariant;
};

export type Sitemap = {
    cannonical?: Maybe<Array<Scalars['JSONObject']['output']>>;
    id: Scalars['ContentID']['output'];
    name?: Maybe<Scalars['String']['output']>;
    parentRef?: Maybe<Scalars['ContentID']['output']>;
    parentType?: Maybe<Scalars['String']['output']>;
    refs?: Maybe<Array<SitemapItem>>;
    title?: Maybe<Scalars['String']['output']>;
    type: Scalars['String']['output'];
};

export enum SitemapFilter {
    Any = 'ANY',
    Exact = 'EXACT',
}

export type SitemapItem = {
    cannonical?: Maybe<Array<Scalars['JSONObject']['output']>>;
    depth?: Maybe<Scalars['Int']['output']>;
    id: Scalars['ContentID']['output'];
    name: Scalars['String']['output'];
    parentRef?: Maybe<Scalars['ContentID']['output']>;
    parentType?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    type: Scalars['String']['output'];
};

export type StringPagination = Pagination & {
    first?: Maybe<Scalars['String']['output']>;
    hasNext: Scalars['Boolean']['output'];
    hasPrevious: Scalars['Boolean']['output'];
    items: Array<Scalars['String']['output']>;
    last?: Maybe<Scalars['String']['output']>;
};

export type SystemInfo = {
    codename: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    language: Scalars['String']['output'];
    lastModified: Scalars['DateTime']['output'];
    name: Scalars['String']['output'];
    type: Scalars['String']['output'];
};

export type Teaser = {
    author_ref?: Maybe<Scalars['String']['output']>;
    date?: Maybe<Scalars['DateTime']['output']>;
    display: Scalars['String']['output'];
    hero?: Maybe<Asset>;
    icon?: Maybe<Asset>;
    id: Scalars['ID']['output'];
    link?: Maybe<Scalars['JSONObject']['output']>;
    locale: Scalars['String']['output'];
    subtitle?: Maybe<Scalars['String']['output']>;
    tag_ref?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    types: Array<Scalars['String']['output']>;
    url?: Maybe<Scalars['JSONObject']['output']>;
    woom: Scalars['JSONObject']['output'];
};

export type TeaserFilter = {
    any: Scalars['Boolean']['input'];
    name: Scalars['String']['input'];
    value?: InputMaybe<Scalars['String']['input']>;
    values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TeaserFilterInput = {
    filters?: InputMaybe<Array<TeaserFilter>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
};

export type TeaserResponse = {
    references: Array<Scalars['String']['output']>;
    refs?: Maybe<PageReferences>;
    teaser: Array<Teaser>;
};

export type TripItemInput = {
    active: Scalars['Boolean']['input'];
    distance: Scalars['Float']['input'];
    id: Scalars['Int']['input'];
    nickname: Scalars['String']['input'];
    person: Scalars['Int']['input'];
};

export enum UrlType {
    Redirect = 'REDIRECT',
    Url = 'URL',
}

export type UserEntry = {
    elements: Scalars['JSONObject']['output'];
    id: Scalars['ID']['output'];
    language: Scalars['String']['output'];
    project: Scalars['String']['output'];
    score: Scalars['Float']['output'];
    types?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserEntryResult = {
    ages: Array<Scalars['JSONObject']['output']>;
    hasNext: Scalars['Boolean']['output'];
    hasPrevious: Scalars['Boolean']['output'];
    items: Array<UserEntry>;
    total: Scalars['Int']['output'];
};

export type Version = {
    cms: Scalars['String']['output'];
    cmsType: Scalars['String']['output'];
    core: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    shop?: Maybe<Scalars['String']['output']>;
    shopType?: Maybe<Scalars['String']['output']>;
};

export type Website = {
    footer?: Maybe<WebsiteFooter>;
    id: Scalars['ID']['output'];
    locale?: Maybe<Scalars['String']['output']>;
    menu?: Maybe<Scalars['String']['output']>;
    navbar?: Maybe<Menu>;
    notifications?: Maybe<Array<Notification>>;
    payment_icons?: Maybe<Array<Asset>>;
    refs: WebsiteReferences;
    seo?: Maybe<Seo>;
    shipment_icons?: Maybe<Array<Asset>>;
    shop?: Maybe<WebsiteShop>;
    social?: Maybe<WebsiteSocial>;
    switch?: Maybe<WebsiteSwitch>;
    types: Array<Scalars['String']['output']>;
};

export type WebsiteFooter = {
    contact?: Maybe<Scalars['JSONObject']['output']>;
    contact_link?: Maybe<Scalars['JSONObject']['output']>;
    legal?: Maybe<Scalars['String']['output']>;
    menu?: Maybe<Menu>;
    newsletter?: Maybe<Scalars['JSONObject']['output']>;
    submenu?: Maybe<Menu>;
};

export type WebsiteReferences = {
    links?: Maybe<Array<Scalars['JSONObject']['output']>>;
};

export type WebsiteShop = {
    content?: Maybe<Array<Scalars['String']['output']>>;
    notify_me?: Maybe<Scalars['JSONObject']['output']>;
    sizeGuide?: Maybe<Scalars['JSONObject']['output']>;
    units?: Maybe<Array<Scalars['String']['output']>>;
    upsell?: Maybe<Array<Scalars['JSONObject']['output']>>;
};

export type WebsiteSocial = {
    facebook?: Maybe<Scalars['String']['output']>;
    instagram?: Maybe<Scalars['String']['output']>;
    linkedin?: Maybe<Scalars['String']['output']>;
    pinterest?: Maybe<Scalars['String']['output']>;
    twitter?: Maybe<Scalars['String']['output']>;
    youtube?: Maybe<Scalars['String']['output']>;
};

export type WebsiteSwitch = {
    copy?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
};

export type WoomColor = {
    id: Scalars['ID']['output'];
    key: Scalars['String']['output'];
    project: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type WoomCountry = {
    cms: Cms;
    default: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    languages: Array<Scalars['String']['output']>;
    name: Scalars['String']['output'];
};

export type WoomNotification = {
    data?: Maybe<Scalars['JSONObject']['output']>;
    id: Scalars['ID']['output'];
    lang: Scalars['String']['output'];
    project: Scalars['String']['output'];
    refs: PageReferences;
};

export type WoomRegion = {
    available: Scalars['DateTime']['output'];
    code: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    languages: Array<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    url?: Maybe<Scalars['String']['output']>;
};

export type CheckoutMutationVariables = Exact<{
    action: CheckoutActionType;
    locale: Scalars['String']['input'];
    checkoutId?: InputMaybe<Scalars['ID']['input']>;
    lineItems?: InputMaybe<Array<InputMaybe<CheckoutLineItemInput>> | InputMaybe<CheckoutLineItemInput>>;
}>;

export type CheckoutMutation = {
    manageCheckout?: {
        checkout?: {
            id: string;
            nativeId?: string | null;
            currencyCode?: CurrencyCode | null;
            createdAt: any;
            updatedAt: any;
            completedAt?: any | null;
            email?: string | null;
            totalQuantity: number;
            taxExempt: boolean;
            taxesIncluded: boolean;
            orderStatusUrl?: string | null;
            webUrl: string;
            totalTax?: { amount: string; currencyCode: CurrencyCode } | null;
            totalPrice?: { amount: string; currencyCode: CurrencyCode } | null;
            subtotalPrice?: { amount: string; currencyCode: CurrencyCode } | null;
            lineItemsSubtotalPrice?: { amount: string; currencyCode: CurrencyCode } | null;
            lineItems: Array<{
                id?: string | null;
                quantity?: number | null;
                shopTitle?: string | null;
                customAttributes?: any | null;
                discountAllocations?: any | null;
                unitPrice?: { amount: string; currencyCode: CurrencyCode } | null;
                variant?: {
                    id: string;
                    nativeId?: string | null;
                    sku?: string | null;
                    barcode?: string | null;
                    shopTitle?: string | null;
                    shopImage?: string | null;
                    quantityAvailable?: number | null;
                    availableForSale?: boolean | null;
                    productRef?: string | null;
                    productType?: string | null;
                    price?: { amount: string; currencyCode: CurrencyCode } | null;
                    compareAtPrice?: { amount: string; currencyCode: CurrencyCode } | null;
                    selectedOptions?: Array<{ name?: string | null; value?: string | null } | null> | null;
                } | null;
            }>;
        } | null;
        userErrors: Array<{ code?: CheckoutErrorCode | null; field?: Array<string | null> | null; message: string }>;
    } | null;
};

export type BikefinderQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']['input']>;
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BikefinderQuery = {
    bikefinder?: { id: string; bikefinder?: any | null; links?: Array<any> | null; types: Array<string> } | null;
    collection?: {
        id: string;
        nativeId: string;
        handle: string;
        products: {
            hasNext: boolean;
            hasPrevious: boolean;
            first?: string | null;
            last?: string | null;
            items: Array<{
                id: string;
                nativeId: string;
                handle: string;
                shopTitle?: string | null;
                shopImage?: string | null;
                productType?: string | null;
                singleBuy?: {
                    id: string;
                    nativeId: string;
                    shippingDate?: any | null;
                    hasMore?: boolean | null;
                    availableForSale?: boolean | null;
                    availabilityState: string;
                    customNaming?: boolean | null;
                    sku?: string | null;
                    title?: string | null;
                } | null;
                content?: {
                    id: string;
                    types?: Array<string> | null;
                    category?: Array<string> | null;
                    weight?: any | null;
                    wheels?: number | null;
                    min_age?: number | null;
                    max_age?: number | null;
                    min_height?: any | null;
                    max_height?: any | null;
                    features?: string | null;
                    description?: string | null;
                    title?: string | null;
                    url?: any | null;
                    overview?: { id: string; data?: any | null } | null;
                } | null;
                priceRange?: { minVariantPrice: { amount: string; currencyCode: CurrencyCode } } | null;
            }>;
        };
    } | null;
};

export type CountryByLanguageQueryVariables = Exact<{
    lang: Scalars['String']['input'];
}>;

export type CountryByLanguageQuery = { countryByLanguage: Array<string> };

export type LanguageSwitcherQueryVariables = Exact<{
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LanguageSwitcherQuery = {
    languageSwitcher?: {
        id: string;
        title?: string | null;
        copy?: string | null;
        sequence: Array<string>;
        image?: { id: string; data?: any | null } | null;
        seo?: { title?: string | null; description?: string | null; image?: { id: string; data?: any | null } | null } | null;
        system: { language: string };
        regions: Array<{ id: string; title: string; code: string; languages: Array<string>; available: any; url?: string | null }>;
    } | null;
};

export type MenuQueryVariables = Exact<{
    locale: Scalars['String']['input'];
    id?: InputMaybe<Scalars['String']['input']>;
    preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type MenuQuery = {
    menu?: {
        id: string;
        locale?: string | null;
        title?: string | null;
        items?: Array<string> | null;
        references?: Array<{
            id: string;
            title: string;
            type?: string | null;
            items?: Array<string> | null;
            depth?: number | null;
            link?: any | null;
            color?: string | null;
        }> | null;
    } | null;
};

export type ShopContentQueryVariables = Exact<{
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    locale: Scalars['String']['input'];
    ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ShopContentQuery = {
    shopContent: Array<{
        id: string;
        title?: string | null;
        code?: string | null;
        text: any;
        links?: Array<string> | null;
        types: Array<string>;
        locale: string;
    }>;
};

export type WebsiteQueryVariables = Exact<{
    locale: Scalars['String']['input'];
    codename?: InputMaybe<Scalars['String']['input']>;
    preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type WebsiteQuery = {
    website?: {
        id: string;
        locale?: string | null;
        menu?: string | null;
        shop?: {
            content?: Array<string> | null;
            units?: Array<string> | null;
            sizeGuide?: any | null;
            notify_me?: any | null;
            upsell?: Array<any> | null;
        } | null;
        switch?: { title?: string | null; copy?: string | null } | null;
        social?: {
            facebook?: string | null;
            youtube?: string | null;
            instagram?: string | null;
            linkedin?: string | null;
            pinterest?: string | null;
            twitter?: string | null;
        } | null;
        seo?: { title?: string | null; description?: string | null; image?: { id: string; data?: any | null } | null } | null;
        payment_icons?: Array<{ id: string; data?: any | null }> | null;
        shipment_icons?: Array<{ id: string; data?: any | null }> | null;
        notifications?: Array<{
            id: string;
            title?: string | null;
            type?: string | null;
            text?: any | null;
            mobile_text?: any | null;
            link?: any | null;
            content?: any | null;
            start?: any | null;
            end?: any | null;
            weighting?: number | null;
            locale: string;
            types?: Array<string> | null;
            icon?: { id: string; data?: any | null } | null;
        }> | null;
        footer?: {
            newsletter?: any | null;
            contact?: any | null;
            contact_link?: any | null;
            legal?: string | null;
            menu?: {
                id: string;
                items?: Array<string> | null;
                references?: Array<{ id: string; title: string; items?: Array<string> | null; link?: any | null; color?: string | null }> | null;
            } | null;
            submenu?: {
                id: string;
                items?: Array<string> | null;
                references?: Array<{ id: string; title: string; items?: Array<string> | null; link?: any | null; color?: string | null }> | null;
            } | null;
        } | null;
        navbar?: {
            id: string;
            items?: Array<string> | null;
            references?: Array<{ id: string; title: string; link?: any | null; color?: string | null }> | null;
        } | null;
        refs: { links?: Array<any> | null };
    } | null;
};

export type PageByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PageByIdQuery = {
    pageById?: {
        id: string;
        locale?: string | null;
        types: Array<string>;
        url?: any | null;
        woom: any;
        system: any;
        seo?: { title?: string | null; description?: string | null; image?: { id: string; data?: any | null } | null } | null;
        cannonical?: Array<{ language: string; url: string; locale: string }> | null;
        page: {
            base?: any | null;
            breadcrumbs?: Array<any> | null;
            content?: any | null;
            product?: {
                id: string;
                nativeId: string;
                title?: string | null;
                description?: string | null;
                types?: Array<string> | null;
                features?: string | null;
                category?: Array<string> | null;
                limit: number;
                max_age?: number | null;
                min_age?: number | null;
                max_height?: any | null;
                min_height?: any | null;
                weight?: any | null;
                wheels?: number | null;
                affirm: boolean;
                customNaming: boolean;
                onlySingleProduct: boolean;
                handle: string;
                shopTitle?: string | null;
                shopImage?: string | null;
                tags?: Array<string | null> | null;
                productType?: string | null;
                hero?: { id: string; data?: any | null } | null;
                overview?: { id: string; data?: any | null } | null;
                shop: { id: string; domain: string };
                priceRange?: {
                    minVariantPrice: { amount: string; currencyCode: CurrencyCode };
                    maxVariantPrice: { amount: string; currencyCode: CurrencyCode };
                } | null;
                options?: Array<{ id?: string | null; name?: string | null; values?: Array<string | null> | null } | null> | null;
                variants?: {
                    total?: number | null;
                    items?: Array<{
                        id: string;
                        nativeId?: string | null;
                        sku?: string | null;
                        barcode?: string | null;
                        hidden: boolean;
                        availableForSale?: boolean | null;
                        quantityAvailable?: number | null;
                        forSale: boolean;
                        availabilityState: ProductAvailability;
                        shippingDate?: any | null;
                        weight?: number | null;
                        weightUnit?: string | null;
                        shopTitle?: string | null;
                        requiresShipping?: boolean | null;
                        currentlyNotInStock?: boolean | null;
                        shopImage?: string | null;
                        content?: { id: string; types?: Array<string> | null; gallery?: Array<{ id: string; data?: any | null }> | null } | null;
                        price?: { amount: string; currencyCode: CurrencyCode } | null;
                        unitPrice?: { amount: string; currencyCode: CurrencyCode } | null;
                        compareAtPrice?: { amount: string; currencyCode: CurrencyCode } | null;
                        shop: { id: string; domain: string };
                        selectedOptions?: Array<{ name?: string | null; value?: string | null } | null> | null;
                    } | null> | null;
                } | null;
            } | null;
        };
        refs: { links: Array<any>; tags: Array<any>; authors: Array<any>; references: Array<any> };
    } | null;
};

export type PageByUrlQueryVariables = Exact<{
    url: Scalars['String']['input'];
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    full?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PageByUrlQuery = {
    pageByUrl?: {
        id: string;
        locale?: string | null;
        url?: any | null;
        types: Array<string>;
        seo?: { title?: string | null; description?: string | null; image?: { id: string; data?: any | null } | null } | null;
        cannonical?: Array<{ language: string; url: string; locale: string }> | null;
        page: {
            base?: any | null;
            breadcrumbs?: Array<any> | null;
            content?: any | null;
            bundle?: {
                price?: string | null;
                compare_at_price?: string | null;
                products?: any | null;
                label?: string | null;
                code?: string | null;
                description?: string | null;
                lead?: string | null;
                requirement?: string | null;
                cartImage?: { id: string; data?: any | null } | null;
                gallery?: Array<{ id: string; data?: any | null }> | null;
            } | null;
            product?: {
                id: string;
                nativeId: string;
                title?: string | null;
                description?: string | null;
                types?: Array<string> | null;
                features?: string | null;
                category?: Array<string> | null;
                limit: number;
                max_age?: number | null;
                min_age?: number | null;
                max_height?: any | null;
                min_height?: any | null;
                weight?: any | null;
                wheels?: number | null;
                customNaming: boolean;
                onlySingleProduct: boolean;
                handle: string;
                affirm: boolean;
                shopTitle?: string | null;
                shopImage?: string | null;
                tags?: Array<string | null> | null;
                productType?: string | null;
                hero?: { id: string; data?: any | null } | null;
                collections: Array<{ id: string; handle: string; nativeId: string; title?: string | null } | null>;
                overview?: { id: string; data?: any | null } | null;
                promo?: { promo: string; banner: string; content?: string | null } | null;
                shop: { id: string; domain: string };
                priceRange?: {
                    minVariantPrice: { amount: string; currencyCode: CurrencyCode };
                    maxVariantPrice: { amount: string; currencyCode: CurrencyCode };
                } | null;
                options?: Array<{ id?: string | null; name?: string | null; values?: Array<string | null> | null } | null> | null;
                variants?: {
                    total?: number | null;
                    items?: Array<{
                        id: string;
                        nativeId?: string | null;
                        sku?: string | null;
                        barcode?: string | null;
                        hidden: boolean;
                        availableForSale?: boolean | null;
                        quantityAvailable?: number | null;
                        forSale: boolean;
                        availabilityState: ProductAvailability;
                        shippingDate?: any | null;
                        weight?: number | null;
                        weightUnit?: string | null;
                        shopTitle?: string | null;
                        requiresShipping?: boolean | null;
                        currentlyNotInStock?: boolean | null;
                        shopImage?: string | null;
                        content?: { id: string; types?: Array<string> | null; gallery?: Array<{ id: string; data?: any | null }> | null } | null;
                        price?: { amount: string; currencyCode: CurrencyCode } | null;
                        unitPrice?: { amount: string; currencyCode: CurrencyCode } | null;
                        compareAtPrice?: { amount: string; currencyCode: CurrencyCode } | null;
                        shop: { id: string; domain: string };
                        selectedOptions?: Array<{ name?: string | null; value?: string | null } | null> | null;
                    } | null> | null;
                } | null;
            } | null;
        };
        refs: { links: Array<any>; authors: Array<any>; tags: Array<any>; references: Array<any> };
    } | null;
};

export type ProductContentByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProductContentByIdQuery = {
    productContentById?: {
        id: string;
        types: Array<string>;
        woom: any;
        system: any;
        seo?: { title?: string | null; description?: string | null; image?: { id: string; data?: any | null } | null } | null;
        page: { base?: any | null; breadcrumbs?: Array<any> | null; content?: any | null; product?: any | null };
        refs: { links: Array<any>; authors: Array<any>; tags: Array<any>; references: Array<any> };
    } | null;
};

export type BundlesQueryVariables = Exact<{
    locale: Scalars['String']['input'];
    preview?: InputMaybe<Scalars['Boolean']['input']>;
    ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type BundlesQuery = {
    bundles?: Array<{
        id: string;
        url: string;
        lastModified: any;
        locale: string;
        title?: string | null;
        price?: string | null;
        compareAtPrice?: string | null;
        availableForSale: boolean;
        requiresConfiguration: boolean;
        description?: string | null;
        cartCode?: string | null;
        leadProducts?: Array<string> | null;
        cartImage?: { id: string; data?: any | null } | null;
        hero?: { id: string; data?: any | null } | null;
        products: Array<{
            id: string;
            nativeId: string;
            shopTitle?: string | null;
            options?: Array<{ name?: string | null; values?: Array<string | null> | null } | null> | null;
            variants?: {
                items?: Array<{
                    id: string;
                    shopTitle?: string | null;
                    orderCode?: string | null;
                    selectedOptions?: Array<{ name?: string | null; value?: string | null } | null> | null;
                } | null> | null;
            } | null;
        }>;
    }> | null;
};

export type CollectionQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']['input']>;
    preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CollectionQuery = {
    collection?: {
        id: string;
        handle: string;
        title: string;
        description?: string | null;
        products: {
            items: Array<{
                id: string;
                handle: string;
                shopTitle?: string | null;
                shopImage?: string | null;
                productType?: string | null;
                limit: number;
                tags?: Array<string | null> | null;
                labels: Array<{ id: string; label?: string | null; color?: string | null; hide: Array<string> } | null>;
                singleBuy?: {
                    id: string;
                    nativeId: string;
                    shippingDate?: any | null;
                    hasMore?: boolean | null;
                    availableForSale?: boolean | null;
                    availabilityState: string;
                    customNaming?: boolean | null;
                    sku?: string | null;
                    title?: string | null;
                } | null;
                content?: {
                    id: string;
                    types?: Array<string> | null;
                    category?: Array<string> | null;
                    weight?: any | null;
                    wheels?: number | null;
                    min_age?: number | null;
                    max_age?: number | null;
                    min_height?: any | null;
                    max_height?: any | null;
                    features?: string | null;
                    description?: string | null;
                    title?: string | null;
                    url?: any | null;
                    overview?: { id: string; data?: any | null } | null;
                    hover?: { id: string; data?: any | null } | null;
                } | null;
                priceRange?: { minVariantPrice: { amount: string; currencyCode: CurrencyCode } } | null;
                options?: Array<{ id?: string | null; name?: string | null; values?: Array<string | null> | null } | null> | null;
            }>;
        };
    } | null;
};

export type BundleQueryVariables = Exact<{
    id: Scalars['ID']['input'];
    locale: Scalars['String']['input'];
    products: Array<BundleProductInput> | BundleProductInput;
}>;

export type BundleQuery = {
    bundle?: {
        id: string;
        products: Array<{
            id: string;
            handle: string;
            nativeId: string;
            shopTitle?: string | null;
            options?: Array<{ id?: string | null; name?: string | null; values?: Array<string | null> | null } | null> | null;
            priceRange?: { minVariantPrice: { amount: string; currencyCode: CurrencyCode } } | null;
            variants?: {
                items?: Array<{
                    id: string;
                    shopImage?: string | null;
                    availabilityState: ProductAvailability;
                    preOrder: boolean;
                    productType?: string | null;
                    customNaming: boolean;
                    quantityAvailable?: number | null;
                    shippingDate?: any | null;
                    requiresShipping?: boolean | null;
                    barcode?: string | null;
                    sku?: string | null;
                    currentlyNotInStock?: boolean | null;
                    price?: { amount: string; currencyCode: CurrencyCode } | null;
                    compareAtPrice?: { amount: string; currencyCode: CurrencyCode } | null;
                    selectedOptions?: Array<{ name?: string | null; value?: string | null } | null> | null;
                } | null> | null;
            } | null;
        }>;
    } | null;
};

export type ProductQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type ProductQuery = {
    product?: {
        id: string;
        nativeId: string;
        handle: string;
        shopTitle?: string | null;
        shopImage?: string | null;
        tags?: Array<string | null> | null;
        productType?: string | null;
        shop: { id: string; domain: string };
        priceRange?: {
            minVariantPrice: { amount: string; currencyCode: CurrencyCode };
            maxVariantPrice: { amount: string; currencyCode: CurrencyCode };
        } | null;
        options?: Array<{ id?: string | null; name?: string | null; values?: Array<string | null> | null } | null> | null;
        variants?: {
            total?: number | null;
            items?: Array<{
                id: string;
                nativeId?: string | null;
                sku?: string | null;
                hidden: boolean;
                availableForSale?: boolean | null;
                quantityAvailable?: number | null;
                forSale: boolean;
                availabilityState: ProductAvailability;
                shippingDate?: any | null;
                weight?: number | null;
                weightUnit?: string | null;
                shopTitle?: string | null;
                requiresShipping?: boolean | null;
                currentlyNotInStock?: boolean | null;
                shopImage?: string | null;
                price?: { amount: string; currencyCode: CurrencyCode } | null;
                unitPrice?: { amount: string; currencyCode: CurrencyCode } | null;
                compareAtPrice?: { amount: string; currencyCode: CurrencyCode } | null;
                shop: { id: string; domain: string };
                selectedOptions?: Array<{ name?: string | null; value?: string | null } | null> | null;
            } | null> | null;
        } | null;
    } | null;
};

export const CheckoutDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'Checkout' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutActionType' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'checkoutId' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'lineItems' } },
                    type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckoutLineItemInput' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'manageCheckout' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'action' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'action' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'checkoutId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'checkoutId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'lineItems' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'lineItems' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'checkout' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'totalQuantity' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalTax' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'totalPrice' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtotalPrice' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lineItemsSubtotalPrice' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'taxExempt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'taxesIncluded' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'orderStatusUrl' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'webUrl' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lineItems' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'unitPrice' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                ],
                                                            },
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'customAttributes' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'discountAllocations' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'variant' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'shopImage' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'quantityAvailable' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'availableForSale' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'price' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'compareAtPrice' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'selectedOptions' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'productRef' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userErrors' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useCheckoutMutation__
 *
 * To run a mutation, you first call `useCheckoutMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCheckoutMutation({
 *   variables: {
 *     action: // value for 'action'
 *     locale: // value for 'locale'
 *     checkoutId: // value for 'checkoutId'
 *     lineItems: // value for 'lineItems'
 *   },
 * });
 */
export function useCheckoutMutation(
    options:
        | VueApolloComposable.UseMutationOptions<CheckoutMutation, CheckoutMutationVariables>
        | ReactiveFunction<VueApolloComposable.UseMutationOptions<CheckoutMutation, CheckoutMutationVariables>> = {},
) {
    return VueApolloComposable.useMutation<CheckoutMutation, CheckoutMutationVariables>(CheckoutDocument, options);
}
export type CheckoutMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CheckoutMutation, CheckoutMutationVariables>;
export const BikefinderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'bikefinder' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bikefinder' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'preview' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'bikefinder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'links' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collection' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'products' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'items' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopImage' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'singleBuy' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'shippingDate' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'availableForSale' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'availabilityState' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'customNaming' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'content' },
                                                            arguments: [
                                                                {
                                                                    kind: 'Argument',
                                                                    name: { kind: 'Name', value: 'preview' },
                                                                    value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                                                                },
                                                            ],
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'overview' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'wheels' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'min_age' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'max_age' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'min_height' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'max_height' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'priceRange' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'minVariantPrice' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'hasNext' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'hasPrevious' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'first' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'last' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useBikefinderQuery__
 *
 * To run a query within a Vue component, call `useBikefinderQuery` and pass it any options that fit your needs.
 * When your component renders, `useBikefinderQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBikefinderQuery({
 *   id: // value for 'id'
 *   locale: // value for 'locale'
 *   preview: // value for 'preview'
 * });
 */
export function useBikefinderQuery(
    variables: BikefinderQueryVariables | VueCompositionApi.Ref<BikefinderQueryVariables> | ReactiveFunction<BikefinderQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<BikefinderQuery, BikefinderQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BikefinderQuery, BikefinderQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<BikefinderQuery, BikefinderQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<BikefinderQuery, BikefinderQueryVariables>(BikefinderDocument, variables, options);
}
export function useBikefinderLazyQuery(
    variables: BikefinderQueryVariables | VueCompositionApi.Ref<BikefinderQueryVariables> | ReactiveFunction<BikefinderQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<BikefinderQuery, BikefinderQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BikefinderQuery, BikefinderQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<BikefinderQuery, BikefinderQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<BikefinderQuery, BikefinderQueryVariables>(BikefinderDocument, variables, options);
}
export type BikefinderQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BikefinderQuery, BikefinderQueryVariables>;
export const CountryByLanguageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'countryByLanguage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryByLanguage' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'lang' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useCountryByLanguageQuery__
 *
 * To run a query within a Vue component, call `useCountryByLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryByLanguageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCountryByLanguageQuery({
 *   lang: // value for 'lang'
 * });
 */
export function useCountryByLanguageQuery(
    variables:
        | CountryByLanguageQueryVariables
        | VueCompositionApi.Ref<CountryByLanguageQueryVariables>
        | ReactiveFunction<CountryByLanguageQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<CountryByLanguageQuery, CountryByLanguageQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CountryByLanguageQuery, CountryByLanguageQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<CountryByLanguageQuery, CountryByLanguageQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<CountryByLanguageQuery, CountryByLanguageQueryVariables>(CountryByLanguageDocument, variables, options);
}
export function useCountryByLanguageLazyQuery(
    variables:
        | CountryByLanguageQueryVariables
        | VueCompositionApi.Ref<CountryByLanguageQueryVariables>
        | ReactiveFunction<CountryByLanguageQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<CountryByLanguageQuery, CountryByLanguageQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CountryByLanguageQuery, CountryByLanguageQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<CountryByLanguageQuery, CountryByLanguageQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<CountryByLanguageQuery, CountryByLanguageQueryVariables>(CountryByLanguageDocument, variables, options);
}
export type CountryByLanguageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
    CountryByLanguageQuery,
    CountryByLanguageQueryVariables
>;
export const LanguageSwitcherDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'LanguageSwitcher' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'languageSwitcher' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'preview' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'copy' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'seo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'system' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'language' } }],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'sequence' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'regions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'languages' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'available' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useLanguageSwitcherQuery__
 *
 * To run a query within a Vue component, call `useLanguageSwitcherQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguageSwitcherQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLanguageSwitcherQuery({
 *   locale: // value for 'locale'
 *   preview: // value for 'preview'
 * });
 */
export function useLanguageSwitcherQuery(
    variables:
        | LanguageSwitcherQueryVariables
        | VueCompositionApi.Ref<LanguageSwitcherQueryVariables>
        | ReactiveFunction<LanguageSwitcherQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<LanguageSwitcherQuery, LanguageSwitcherQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LanguageSwitcherQuery, LanguageSwitcherQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<LanguageSwitcherQuery, LanguageSwitcherQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<LanguageSwitcherQuery, LanguageSwitcherQueryVariables>(LanguageSwitcherDocument, variables, options);
}
export function useLanguageSwitcherLazyQuery(
    variables:
        | LanguageSwitcherQueryVariables
        | VueCompositionApi.Ref<LanguageSwitcherQueryVariables>
        | ReactiveFunction<LanguageSwitcherQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<LanguageSwitcherQuery, LanguageSwitcherQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LanguageSwitcherQuery, LanguageSwitcherQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<LanguageSwitcherQuery, LanguageSwitcherQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<LanguageSwitcherQuery, LanguageSwitcherQueryVariables>(LanguageSwitcherDocument, variables, options);
}
export type LanguageSwitcherQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
    LanguageSwitcherQuery,
    LanguageSwitcherQueryVariables
>;
export const MenuDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'menu' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'menu' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'preview' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'items' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'references' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'items' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'depth' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useMenuQuery__
 *
 * To run a query within a Vue component, call `useMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMenuQuery({
 *   locale: // value for 'locale'
 *   id: // value for 'id'
 *   preview: // value for 'preview'
 * });
 */
export function useMenuQuery(
    variables: MenuQueryVariables | VueCompositionApi.Ref<MenuQueryVariables> | ReactiveFunction<MenuQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<MenuQuery, MenuQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MenuQuery, MenuQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<MenuQuery, MenuQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<MenuQuery, MenuQueryVariables>(MenuDocument, variables, options);
}
export function useMenuLazyQuery(
    variables: MenuQueryVariables | VueCompositionApi.Ref<MenuQueryVariables> | ReactiveFunction<MenuQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<MenuQuery, MenuQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MenuQuery, MenuQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<MenuQuery, MenuQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<MenuQuery, MenuQueryVariables>(MenuDocument, variables, options);
}
export type MenuQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MenuQuery, MenuQueryVariables>;
export const ShopContentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ShopContent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shopContent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'preview' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'links' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useShopContentQuery__
 *
 * To run a query within a Vue component, call `useShopContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopContentQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useShopContentQuery({
 *   preview: // value for 'preview'
 *   locale: // value for 'locale'
 *   ids: // value for 'ids'
 * });
 */
export function useShopContentQuery(
    variables: ShopContentQueryVariables | VueCompositionApi.Ref<ShopContentQueryVariables> | ReactiveFunction<ShopContentQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<ShopContentQuery, ShopContentQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ShopContentQuery, ShopContentQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<ShopContentQuery, ShopContentQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<ShopContentQuery, ShopContentQueryVariables>(ShopContentDocument, variables, options);
}
export function useShopContentLazyQuery(
    variables: ShopContentQueryVariables | VueCompositionApi.Ref<ShopContentQueryVariables> | ReactiveFunction<ShopContentQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<ShopContentQuery, ShopContentQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ShopContentQuery, ShopContentQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<ShopContentQuery, ShopContentQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<ShopContentQuery, ShopContentQueryVariables>(ShopContentDocument, variables, options);
}
export type ShopContentQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ShopContentQuery, ShopContentQueryVariables>;
export const WebsiteDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'website' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'codename' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'website' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'codename' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'codename' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'preview' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'shop' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'units' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sizeGuide' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'notify_me' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'upsell' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'switch' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'copy' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'social' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'facebook' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'youtube' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'instagram' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'linkedin' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'pinterest' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'twitter' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'seo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'menu' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'payment_icons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'shipment_icons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'notifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'icon' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'mobile_text' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'weighting' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'footer' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'menu' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'items' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'references' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'items' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'submenu' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'items' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'references' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'items' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'newsletter' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'contact' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'contact_link' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'legal' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'navbar' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'items' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'references' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'refs' },
                                    selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'links' } }] },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useWebsiteQuery__
 *
 * To run a query within a Vue component, call `useWebsiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useWebsiteQuery({
 *   locale: // value for 'locale'
 *   codename: // value for 'codename'
 *   preview: // value for 'preview'
 * });
 */
export function useWebsiteQuery(
    variables: WebsiteQueryVariables | VueCompositionApi.Ref<WebsiteQueryVariables> | ReactiveFunction<WebsiteQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<WebsiteQuery, WebsiteQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WebsiteQuery, WebsiteQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<WebsiteQuery, WebsiteQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<WebsiteQuery, WebsiteQueryVariables>(WebsiteDocument, variables, options);
}
export function useWebsiteLazyQuery(
    variables: WebsiteQueryVariables | VueCompositionApi.Ref<WebsiteQueryVariables> | ReactiveFunction<WebsiteQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<WebsiteQuery, WebsiteQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<WebsiteQuery, WebsiteQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<WebsiteQuery, WebsiteQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<WebsiteQuery, WebsiteQueryVariables>(WebsiteDocument, variables, options);
}
export type WebsiteQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<WebsiteQuery, WebsiteQueryVariables>;
export const PageByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PageById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'preview' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'woom' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'seo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cannonical' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'page' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'breadcrumbs' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'product' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'hero' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'overview' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                                ],
                                                            },
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'max_age' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'min_age' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'max_height' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'min_height' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'wheels' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'affirm' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'customNaming' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'onlySingleProduct' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopImage' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'shop' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'priceRange' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'minVariantPrice' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'maxVariantPrice' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'options' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'variants' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'items' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'content' },
                                                                                    arguments: [
                                                                                        {
                                                                                            kind: 'Argument',
                                                                                            name: { kind: 'Name', value: 'preview' },
                                                                                            value: { kind: 'BooleanValue', value: true },
                                                                                        },
                                                                                    ],
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                                            { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'gallery' },
                                                                                                selectionSet: {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections: [
                                                                                                        {
                                                                                                            kind: 'Field',
                                                                                                            name: { kind: 'Name', value: 'id' },
                                                                                                        },
                                                                                                        {
                                                                                                            kind: 'Field',
                                                                                                            name: { kind: 'Name', value: 'data' },
                                                                                                        },
                                                                                                    ],
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'availableForSale' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'quantityAvailable' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'forSale' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'availabilityState' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'shippingDate' } },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'price' },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'amount' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'currencyCode' },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'unitPrice' },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'amount' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'currencyCode' },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'compareAtPrice' },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'amount' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'currencyCode' },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'requiresShipping' } },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'currentlyNotInStock' },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'shop' },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'domain' },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'selectedOptions' },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'shopImage' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'refs' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'links' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'authors' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'references' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __usePageByIdQuery__
 *
 * To run a query within a Vue component, call `usePageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageByIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePageByIdQuery({
 *   id: // value for 'id'
 *   preview: // value for 'preview'
 * });
 */
export function usePageByIdQuery(
    variables: PageByIdQueryVariables | VueCompositionApi.Ref<PageByIdQueryVariables> | ReactiveFunction<PageByIdQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<PageByIdQuery, PageByIdQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageByIdQuery, PageByIdQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageByIdQuery, PageByIdQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<PageByIdQuery, PageByIdQueryVariables>(PageByIdDocument, variables, options);
}
export function usePageByIdLazyQuery(
    variables: PageByIdQueryVariables | VueCompositionApi.Ref<PageByIdQueryVariables> | ReactiveFunction<PageByIdQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<PageByIdQuery, PageByIdQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageByIdQuery, PageByIdQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageByIdQuery, PageByIdQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<PageByIdQuery, PageByIdQueryVariables>(PageByIdDocument, variables, options);
}
export type PageByIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PageByIdQuery, PageByIdQueryVariables>;
export const PageByUrlDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PageByUrl' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'full' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageByUrl' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'url' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'url' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'preview' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'full' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'full' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'seo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cannonical' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'page' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'breadcrumbs' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bundle' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'compare_at_price' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'products' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'cartImage' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'gallery' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                                ],
                                                            },
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'lead' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'requirement' } },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'product' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'hero' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'collections' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'overview' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                                ],
                                                            },
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'max_age' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'min_age' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'max_height' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'min_height' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'wheels' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'customNaming' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'onlySingleProduct' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'affirm' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopImage' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'promo' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'promo' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'banner' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'shop' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'priceRange' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'minVariantPrice' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'maxVariantPrice' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'options' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'variants' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'items' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'content' },
                                                                                    arguments: [
                                                                                        {
                                                                                            kind: 'Argument',
                                                                                            name: { kind: 'Name', value: 'preview' },
                                                                                            value: {
                                                                                                kind: 'Variable',
                                                                                                name: { kind: 'Name', value: 'preview' },
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                                            { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'gallery' },
                                                                                                selectionSet: {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections: [
                                                                                                        {
                                                                                                            kind: 'Field',
                                                                                                            name: { kind: 'Name', value: 'id' },
                                                                                                        },
                                                                                                        {
                                                                                                            kind: 'Field',
                                                                                                            name: { kind: 'Name', value: 'data' },
                                                                                                        },
                                                                                                    ],
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'availableForSale' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'quantityAvailable' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'forSale' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'availabilityState' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'shippingDate' } },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'price' },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'amount' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'currencyCode' },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'unitPrice' },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'amount' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'currencyCode' },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'compareAtPrice' },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'amount' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'currencyCode' },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'requiresShipping' } },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'currentlyNotInStock' },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'shop' },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'domain' },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: 'selectedOptions' },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'shopImage' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'refs' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'links' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'authors' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'references' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __usePageByUrlQuery__
 *
 * To run a query within a Vue component, call `usePageByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageByUrlQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePageByUrlQuery({
 *   url: // value for 'url'
 *   locale: // value for 'locale'
 *   preview: // value for 'preview'
 *   full: // value for 'full'
 * });
 */
export function usePageByUrlQuery(
    variables: PageByUrlQueryVariables | VueCompositionApi.Ref<PageByUrlQueryVariables> | ReactiveFunction<PageByUrlQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<PageByUrlQuery, PageByUrlQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageByUrlQuery, PageByUrlQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageByUrlQuery, PageByUrlQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<PageByUrlQuery, PageByUrlQueryVariables>(PageByUrlDocument, variables, options);
}
export function usePageByUrlLazyQuery(
    variables: PageByUrlQueryVariables | VueCompositionApi.Ref<PageByUrlQueryVariables> | ReactiveFunction<PageByUrlQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<PageByUrlQuery, PageByUrlQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageByUrlQuery, PageByUrlQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageByUrlQuery, PageByUrlQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<PageByUrlQuery, PageByUrlQueryVariables>(PageByUrlDocument, variables, options);
}
export type PageByUrlQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PageByUrlQuery, PageByUrlQueryVariables>;
export const ProductContentByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'productContentById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productContentById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'preview' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'woom' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'system' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'seo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'page' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'breadcrumbs' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'product' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'refs' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'links' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'authors' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'references' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useProductContentByIdQuery__
 *
 * To run a query within a Vue component, call `useProductContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductContentByIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductContentByIdQuery({
 *   id: // value for 'id'
 *   preview: // value for 'preview'
 * });
 */
export function useProductContentByIdQuery(
    variables:
        | ProductContentByIdQueryVariables
        | VueCompositionApi.Ref<ProductContentByIdQueryVariables>
        | ReactiveFunction<ProductContentByIdQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<ProductContentByIdQuery, ProductContentByIdQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductContentByIdQuery, ProductContentByIdQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductContentByIdQuery, ProductContentByIdQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<ProductContentByIdQuery, ProductContentByIdQueryVariables>(ProductContentByIdDocument, variables, options);
}
export function useProductContentByIdLazyQuery(
    variables:
        | ProductContentByIdQueryVariables
        | VueCompositionApi.Ref<ProductContentByIdQueryVariables>
        | ReactiveFunction<ProductContentByIdQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<ProductContentByIdQuery, ProductContentByIdQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductContentByIdQuery, ProductContentByIdQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductContentByIdQuery, ProductContentByIdQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<ProductContentByIdQuery, ProductContentByIdQueryVariables>(
        ProductContentByIdDocument,
        variables,
        options,
    );
}
export type ProductContentByIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
    ProductContentByIdQuery,
    ProductContentByIdQueryVariables
>;
export const BundlesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'bundles' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                    type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bundles' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'preview' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ids' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'compareAtPrice' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'availableForSale' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'requiresConfiguration' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'cartCode' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cartImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hero' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'leadProducts' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'products' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'options' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'variants' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'items' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'orderCode' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'selectedOptions' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useBundlesQuery__
 *
 * To run a query within a Vue component, call `useBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundlesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBundlesQuery({
 *   locale: // value for 'locale'
 *   preview: // value for 'preview'
 *   ids: // value for 'ids'
 * });
 */
export function useBundlesQuery(
    variables: BundlesQueryVariables | VueCompositionApi.Ref<BundlesQueryVariables> | ReactiveFunction<BundlesQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<BundlesQuery, BundlesQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BundlesQuery, BundlesQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<BundlesQuery, BundlesQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<BundlesQuery, BundlesQueryVariables>(BundlesDocument, variables, options);
}
export function useBundlesLazyQuery(
    variables: BundlesQueryVariables | VueCompositionApi.Ref<BundlesQueryVariables> | ReactiveFunction<BundlesQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<BundlesQuery, BundlesQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BundlesQuery, BundlesQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<BundlesQuery, BundlesQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<BundlesQuery, BundlesQueryVariables>(BundlesDocument, variables, options);
}
export type BundlesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BundlesQuery, BundlesQueryVariables>;
export const CollectionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'collection' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'collection' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'products' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'items' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopImage' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'labels' },
                                                            arguments: [
                                                                {
                                                                    kind: 'Argument',
                                                                    name: { kind: 'Name', value: 'preview' },
                                                                    value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                                                                },
                                                            ],
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'hide' } },
                                                                ],
                                                            },
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'singleBuy' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'shippingDate' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'availableForSale' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'availabilityState' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'customNaming' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'content' },
                                                            arguments: [
                                                                {
                                                                    kind: 'Argument',
                                                                    name: { kind: 'Name', value: 'preview' },
                                                                    value: { kind: 'Variable', name: { kind: 'Name', value: 'preview' } },
                                                                },
                                                            ],
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'types' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'overview' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'hover' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'wheels' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'min_age' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'max_age' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'min_height' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'max_height' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'priceRange' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'minVariantPrice' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'options' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useCollectionQuery__
 *
 * To run a query within a Vue component, call `useCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCollectionQuery({
 *   id: // value for 'id'
 *   preview: // value for 'preview'
 * });
 */
export function useCollectionQuery(
    variables: CollectionQueryVariables | VueCompositionApi.Ref<CollectionQueryVariables> | ReactiveFunction<CollectionQueryVariables> = {},
    options:
        | VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<CollectionQuery, CollectionQueryVariables>(CollectionDocument, variables, options);
}
export function useCollectionLazyQuery(
    variables: CollectionQueryVariables | VueCompositionApi.Ref<CollectionQueryVariables> | ReactiveFunction<CollectionQueryVariables> = {},
    options:
        | VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<CollectionQuery, CollectionQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<CollectionQuery, CollectionQueryVariables>(CollectionDocument, variables, options);
}
export type CollectionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CollectionQuery, CollectionQueryVariables>;
export const BundleDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'bundle' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'products' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BundleProductInput' } } },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bundle' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'locale' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'products' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'products' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'products' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'options' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priceRange' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'minVariantPrice' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'variants' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'items' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'price' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'compareAtPrice' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'shopImage' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'selectedOptions' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                                                            ],
                                                                        },
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'availabilityState' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'preOrder' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'customNaming' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'quantityAvailable' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'shippingDate' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'requiresShipping' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'barcode' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'currentlyNotInStock' } },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useBundleQuery__
 *
 * To run a query within a Vue component, call `useBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBundleQuery({
 *   id: // value for 'id'
 *   locale: // value for 'locale'
 *   products: // value for 'products'
 * });
 */
export function useBundleQuery(
    variables: BundleQueryVariables | VueCompositionApi.Ref<BundleQueryVariables> | ReactiveFunction<BundleQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<BundleQuery, BundleQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BundleQuery, BundleQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<BundleQuery, BundleQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<BundleQuery, BundleQueryVariables>(BundleDocument, variables, options);
}
export function useBundleLazyQuery(
    variables: BundleQueryVariables | VueCompositionApi.Ref<BundleQueryVariables> | ReactiveFunction<BundleQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<BundleQuery, BundleQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BundleQuery, BundleQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<BundleQuery, BundleQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<BundleQuery, BundleQueryVariables>(BundleDocument, variables, options);
}
export type BundleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BundleQuery, BundleQueryVariables>;
export const ProductDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'product' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'handle' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'shopImage' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'shop' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'priceRange' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'minVariantPrice' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'maxVariantPrice' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'values' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'variants' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'items' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'nativeId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'availableForSale' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'quantityAvailable' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'forSale' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'availabilityState' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shippingDate' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'price' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'unitPrice' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'compareAtPrice' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
                                                                ],
                                                            },
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'weightUnit' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopTitle' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'requiresShipping' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'currentlyNotInStock' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'shop' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'selectedOptions' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                                                ],
                                                            },
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'shopImage' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode;

/**
 * __useProductQuery__
 *
 * To run a query within a Vue component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductQuery({
 *   id: // value for 'id'
 * });
 */
export function useProductQuery(
    variables: ProductQueryVariables | VueCompositionApi.Ref<ProductQueryVariables> | ReactiveFunction<ProductQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables>> = {},
) {
    return VueApolloComposable.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, variables, options);
}
export function useProductLazyQuery(
    variables: ProductQueryVariables | VueCompositionApi.Ref<ProductQueryVariables> | ReactiveFunction<ProductQueryVariables>,
    options:
        | VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables>
        | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables>>
        | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductQuery, ProductQueryVariables>> = {},
) {
    return VueApolloComposable.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, variables, options);
}
export type ProductQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProductQuery, ProductQueryVariables>;
