import { PageByUrlQuery, PageByUrlQueryVariables, WebsiteQuery, WebsiteQueryVariables } from '~/graphql/generated';

type URLParam = { key: string; value: string };

class URLParams {
    private params: URLParam[];
    constructor() {
        this.params = [];
    }

    private add(key: string, value: string) {
        this.params.push({ key, value });
    }

    // If value is null or undefined, do not add it
    addMaybe(key: string, param: string | boolean | undefined | null) {
        if (typeof param === 'boolean') {
            this.add(key, param.toString());
        } else if (typeof param === 'string') {
            this.add(key, param);
        }
    }

    static fromVariables(variables: any) {
        const output = new URLParams();
        const keys = Object.keys(variables);

        for (const key in keys) {
            const actualKey = keys[key];
            output.addMaybe(actualKey, variables[actualKey]);
        }
        return output;
    }

    toParamsString() {
        if (this.params.length === 0) {
            return '';
        }

        this.params.sort((a, b) => {
            return a.key < b.key ? -1 : 1;
        });

        const parametersString =
            '?' +
            this.params
                .map((param) => {
                    return `${param.key}=${param.value}`;
                })
                .join('&');

        return parametersString;
    }
}

const getQueryData = async <Query, QueryVariables>(variables: QueryVariables, url: string): Promise<Query> => {
    const params = URLParams.fromVariables(variables);

    const data: Query = await $fetch(`${url}${params.toParamsString()}`);

    return data;
};

export const getWebsiteQueryData = async (variables: WebsiteQueryVariables): Promise<WebsiteQuery> => {
    return await getQueryData(variables, '/api/website');
};

export const getPageByUrlQueryData = async (variables: PageByUrlQueryVariables): Promise<PageByUrlQuery> => {
    return await getQueryData(variables, '/api/page-by-url');
};
