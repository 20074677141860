import { defineStore } from 'pinia';
import { LocaleType, i18n } from '~/plugins/i18n';
// import messages from '~~/lang/en';

type I18NStateType = {
    locale: string;
    lang: string;
    // messages: any;
    loaded: string[];
};

const defaultI18nState: I18NStateType = {
    locale: 'en',
    lang: 'en',
    // messages: {
    //     ...messages
    // },
    loaded: ['en'],
};

type TranslationType = {
    locale: LocaleType;
    lang: string;
};

// https://pinia.vuejs.org/core-concepts/#setup-stores
// export const useI18nStore = defineStore('woom-store-i18n', () => {
const state = ref<I18NStateType>(defaultI18nState);

const mutations = {
    setTranslation: (data: TranslationType) => {
        const lang = data.lang;
        if (!state.value.loaded.includes(lang)) {
            state.value.loaded.push(lang);
        }
        const locale: LocaleType = data.locale;

        // const messages = data.transfile;
        // const mkeys = Object.keys(messages);
        // for (const mk of mkeys) {
        //     const k = `${mk}`;
        //     // console.log('set local message', k)
        //     //i18n.setLocaleMessage(mk, messages[k]);
        //     //state.messages[k] = messages[k];
        // }
        state.value.locale = locale;
        state.value.lang = lang;
        i18n.global.locale.value = locale;
    },
    setLocale: (locale: LocaleType) => {
        // console.log('setting locale', locale);
        state.value.locale = locale;
        i18n.global.locale.value = locale;
        state.value.lang = locale.split('-')[0];
    },
};

const actions = {
    setLocale: async (locale: LocaleType) => {
        const lang = locale.split(/[-_]/)[0];
        // console.log('set locale', locale)
        // if (!state.value.loaded.includes(`${lang}`)) {
        //     // eslint-disable-next-line no-async-promise-executor
        //     return new Promise<void>(async (resolve, reject) => {
        //         try {
        //             //const msg = (await import(`../lang/${lang}.js`)).default;
        //             // console.log('got message', msg)
        //             const translation: TranslationType = {
        //                 //transfile: msg,
        //                 locale,
        //                 lang
        //             };
        //             mutations.setTranslation(translation);
        //             mutations.setLocale(locale);

        //             resolve();
        //         } catch (err) {
        //             console.log('locale error', err);
        //             reject(err);
        //         }
        //     });
        // } else {

        return new Promise<void>((resolve) => {
            mutations.setTranslation({
                locale,
                lang,
                // ,
                // transfile: state.value.messages
            });

            mutations.setLocale(locale);

            resolve();
        });
        // }
    },
};

// export { state, actions };

// https://pinia.vuejs.org/core-concepts/#setup-stores
export const useI18nStore = defineStore('woom-store-i8n', () => {
    return { state, actions };
});
